import React from "react";
import { useState } from 'react';
import axiosClient from '../../axios-client';
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function GaleriaProductos(props){

  let permisos = props.permisosAsignados;
  if(!permisos.includes('productos_view') && permisos!='-1'){
    window.location = '/home';
  }

    const { handleSubmit } = useForm();
    const [producto, setProducto] = useState([]);
    const [codigo, setCodigo] = useState([]);
    const [galeria, setGaleria] = useState([]);
    const {id} = useParams();

    const [selectedFile, setSelectedFile] = useState(null);

    const dataProductos = () =>{
      let payload = {
        id
      }
      axiosClient.post('/obtener-producto', payload)
      .then(({ data }) => {
        setProducto(data[0].codigo+' - '+data[0].titulo);
        setGaleria(data[0].galeria)
        setCodigo(data[0].codigo)
      }).catch(function (error) {
          console.log(error);
      });
    }

    const onSubmitImagen = () => {
        let payload = {
            file: selectedFile,
            id,
            codigo,
            id_user: localStorage.getItem("ID_USER")
        }
        axiosClient.post("/agregar-imagen-galeria", 
            payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
        }).then(({ data }) => {
            if(data.icon=='error'){
                Swal.fire({
                    icon: data.icon,
                    text: data.message
                })
            }else{
                window.location = "/productos-galeria/"+id;
            }
        }).catch((err) => {
            const response = err.response;
            console.log(response);
        });
    }

    const eliminarRegistro = (id_registro) => {
      Swal.fire({ 
        text: "Confirmar eliminación de registro", 
        icon: "info", 
        showCancelButton: !0, 
        confirmButtonText: "Enviar", 
        confirmButtonColor: '#86E736', 
        cancelButtonText: "Cancelar",
      }).then((e) => {
        if(e.isConfirmed){
          let payload = {
            id_registro,
            estado: '5',
            tabla: 'productos_galeria'
          }
          axiosClient.post("/enviar-estado-tabla", payload)
            .then(({ data }) => {
            window.location = "/productos-galeria/"+id;
          })
          .catch((err) => {
            const response = err.response;
            console.log(response);
          });
        }
      });
    };
  
    useEffect(() => {
      dataProductos();
    }, []);
    return (
      <main>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h1>Galería producto ({producto})</h1>
                    <div className="top-right-button-container">
                    <div className="btn-group">
                        <button className="btn btn-primary" type="button" data-toggle="modal" data-target="#modalGaleria">
                        AGREGAR
                        </button>
                    </div>
                    </div>
                    <div className="separator" />
                </div>
            </div>
          <div className="row mb-4">
            <div className="col-12 mb-4">
              <div className="card">
                <div className="card-body">
                  <table className="data-table responsive cont_tabla">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Imagen</th>
                        <th>Fecha de creación</th>
                        <th>Estado</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      { galeria.map((registro, i) => (
                        <tr key={i}>
                          <td>{registro.id}</td>
                          <td>
                            <img src={registro.image} alt="" width="50%"/>
                          </td>
                          <td>{registro.fecha}</td>
                          <td>
                            <label className="badge badge-success">Activo</label>
                          </td>
                          <td>
                            <button
                                id="btnGroupDrop1"
                                type="button"
                                className="btn btn-secondary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Accion
                            </button>
                            <div
                                className="dropdown-menu"
                                aria-labelledby="btnGroupDrop1"
                            >
                                <a className="dropdown-item" onClick={() => eliminarRegistro(registro.id)}>Eliminar</a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
        className="modal fade"
        id="modalGaleria"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Cargar imagen galería
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <h5 className="card-title" />
                <div>
                  <form onSubmit={handleSubmit(onSubmitImagen)}>
                    <div className="comment-contaiener">
                        <div className="form-group has-float-label">
                            <label
                            className="btn btn-outline-primary btn-upload"
                            htmlFor="inputImage"
                            title="Cargue archivo de imagen"
                            >
                            <input
                                id="inputImage"
                                type="file"
                                className="sr-only"
                                accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                required
                            />
                            Cargar foto de producto
                            </label>
                            &nbsp;<label>{selectedFile ? selectedFile.name : ''}</label>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-secondary btn-block mb-1"
                        >
                            Cargar
                        </button>
                    </div>
                  </form>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </main>
    )
  }

export default GaleriaProductos