import React, { useState, useEffect, createRef } from "react";
import { useForm } from "react-hook-form";
import axiosClient from "../../axios-client";

function AgregarItems(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('inventario_add') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [productos, setProductos] = useState([]);

  const productoRef = createRef();
  const ubicacionRef = createRef();
  const cantidadRef = createRef();

  const dataProductos = () =>{
    axiosClient.get('/obtener-productos')
    .then(({ data }) => {
      setProductos(data);
    }).catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_producto: productoRef.current.value,
      cantidad: cantidadRef.current.value,
      tipo: '1'
    }
    axiosClient.post('/agregar-inventario', payload)
    .then(({ data }) => {
      window.location = "/inventario-agregar-item";
    }).catch(function (error) {
        console.log(error);
    });
  }

  useEffect(() => {
    dataProductos();
  }, []);


  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Agregar Items</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-xl-7 ">
            <div className="card mb-4 justify-content-center">
              <div className="lightbox">
                <img
                  alt="detail"
                  src="img/fotoInventario.jpeg"
                  className="responsive border-0 card-img-top mb-3"
                />
              </div>
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group has-float-label">
                    <span>Producto</span>
                    <select
                      className="form-control select2-single"
                      name="jQueryTopLabelsState"
                      required
                      data-width="100%"
                      ref={productoRef}
                    >
                      <option />
                      { productos.map((registro, i) => (
                        <option value={registro.id} key={i}>{registro.codigo} - {registro.titulo}</option>
                      ))}
                    </select>                    
                  </div>
                  <div className="form-group mb-1">
                   {/* <label />
                    <div className="form-group has-float-label">
                      <label>Ubicación</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        ref={ubicacionRef}
                      />
                    </div>*/}

                    <div className="form-group has-float-label">
                      <label>Cantidad</label>
                      <input
                        type="text"
                        className="form-control"
                        ref={cantidadRef}
                        required
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block mb-1"
                    >
                    GUARDAR INGRESO
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AgregarItems;
