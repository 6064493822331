import React, { useState, useEffect } from "react";
import axiosClient from "../../axios-client";

function TodosProductos(props) {


  let permisos = props.permisosAsignados;
  if(!permisos.includes('productos_view') && permisos!='-1'){
    window.location = '/home';
  }
  const [productos, setProductos] = useState([]);

  const dataProductos = () =>{
    let payload;
    axiosClient.get('/obtener-productos', payload)
    .then(({ data }) => {
      setProductos(data);

    }).catch(function (error) {
        console.log(error);
    });
  }

  const irDetalle = (id) => {
    window.location = "/productos-detalles/"+id;
  };

  const irAgregar = () => {
    window.location = "/productos-agregar";
  };

  useEffect(() => {
    dataProductos();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Todos los Productos</h1>
            {permisos.includes('productos_add') || permisos=='-1' ?
            <div className="top-right-button-container">
              <div className="btn-group">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={irAgregar}
                >
                  AGREGAR
                </button>
              </div>
            </div>
            : ''}
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
              {/*<DataTable columns={columns} data={productos} pagination/>*/}
                <table className="data-table responsive cont_tabla" data-order='[[ 0, "desc" ]]'>
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Título</th>
                      <th>Descripción</th>
                      <th>Color</th>
                      <th>Material</th>
                      <th>Empaque</th>
                    </tr>
                  </thead>
                  <tbody>
                  { productos.map((registro, i) => (
                    <tr onClick={() => irDetalle(registro.id)} key={i} className="activatePointer">
                      <td>{registro.codigo}</td>
                      <td>{registro.titulo}</td>
                      <td>{registro.descripcion}</td>
                      <td>{registro.nombreColor}</td>
                      <td>{registro.nombreMaterial}</td>
                      <td>{registro.nombreEmpaque}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default TodosProductos;
