import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";

function CarteraPendiente(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('cartera_view') && permisos!='-1'){
    window.location = '/home';
  }

  const [cartera, setCartera] = useState([]);

  const consultarCartera = () => {
    let payload = {
      estado: '1'
    }
    axiosClient.post('/obtener-cartera', payload)
    .then(({data})=> {
      setCartera(data)
    })
  }

  const verEstadoFactura = (id) => {
    window.location = '/cartera-detalle-factura/'+id;
  }

  const verEstadoCliente = (id) => {
    window.location = '/cartera-detalle-cliente/'+id;
  }

  

  useEffect(() => {
    consultarCartera()
  }, [])

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Cartera Pendiente</h1>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
          <br></br><br></br>
            <table
              id="datatableRos"
              className="data-table responsive nowrap cont_tabla_export"
              data-order='[[ 1, "desc" ]]'
            >
              <thead>
                <tr>
                  <th>Documento</th>
                  <th>Cliente</th>
                  <th>Valor</th>
                  <th>Estado</th>
                  <th>Fecha Limite</th>
                </tr>
              </thead>
              <tbody>
                {cartera.map((registro, i) => (
                  <tr key={i}>
                    <td onClick={() => verEstadoFactura(registro.id)} className="activatePointer">
                      <p className="list-item-heading">{registro.numero_documento}</p>
                    </td>
                    <td onClick={() => verEstadoCliente(registro.id_clientes)} className="activatePointer">
                      <p className="list-item-heading">
                      {registro.razon_social ? registro.razon_social+' - ' : ''}{registro.nombre}
                      </p>
                    </td>
                    <td>
                      <p className="list-item-heading">{registro.total_formato}</p>
                    </td>
                    <td>
                      <span className="badge badge-pill badge-outline-success mb-1">
                        {registro.abonos}
                      </span>
                      <br />
                      <span className="badge badge-pill badge-outline-danger mb-1">
                        {registro.restante}
                      </span>
                    </td>
                    <td>
                      <p className="text-muted">{registro.fecha_vencimiento}</p>
                      <span className={registro.class_restantes}>
                        {registro.dias_restantes}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CarteraPendiente;
