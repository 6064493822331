import { createBrowserRouter } from "react-router-dom";
import Login from "./paginas/Login.jsx";
import Inicio from "./paginas/Inicio.jsx";
import General from "./templates/General.jsx";
import Home from "./paginas/Home.jsx";
import TodosClientes from "./paginas/clientes/TodosClientes.jsx";
import MisClientes from "./paginas/clientes/MisClientes.jsx";
import ParametrosClientes from "./paginas/clientes/Parametros.jsx";
import ResumenClientes from "./paginas/clientes/Resumen.jsx";
import NotFound from "./paginas/NotFound.jsx";
import DetalleCliente from "./paginas/clientes/DetalleCliente.jsx";
import AgregarCliente from "./paginas/clientes/AgregarCliente.jsx";
import TodosProductos from "./paginas/productos/TodosProductos.jsx";
import ParametrosProductos from "./paginas/productos/Parametros.jsx";
import ResumenProductos from "./paginas/productos/Resumen.jsx";
import ResumenPlanos from "./paginas/planos/Resumen.jsx";
import TodosPlanos from "./paginas/planos/TodosPlanos.jsx";
import NuevoPedido from "./paginas/pedidos/NuevoPedido.jsx";
import TodosPedidos from "./paginas/pedidos/TodosPedidos.jsx";
import MisPedidos from "./paginas/pedidos/MisPedidos.jsx";
import ResumenPedidos from "./paginas/pedidos/Resumen.jsx";
import VerPlanos from "./paginas/planta/VerPlanos.jsx";
import EstadoPlanta from "./paginas/planta/EstadoPlanta.jsx";
import ResumenPlanta from "./paginas/planta/Resumen.jsx";
import ControlProduccion from "./paginas/produccion/ControlProduccion.jsx";
import ResumenProduccion from "./paginas/produccion/Resumen.jsx";
import VerDespachos from "./paginas/despachos/VerDespachos.jsx";
import ListaPrecios from "./paginas/precios/ListaPrecios.jsx";
import CrearLista from "./paginas/precios/CrearLista.jsx";
import ResumenPrecios from "./paginas/precios/Resumen.jsx";
import VerInventario from "./paginas/inventarios/VerInventario.jsx";
import AgregarItems from "./paginas/inventarios/AgregarItems.jsx";
import RetirarItems from "./paginas/inventarios/RetirarItems.jsx";
import CarteraPendiente from "./paginas/cartera/CarteraPendiente.jsx";
import HistorialCartera from "./paginas/cartera/HistorialCartera.jsx";
import ArticulosPublicados from "./paginas/paginaweb/ArticulosPublicados.jsx";
import Informacion from "./paginas/paginaweb/Informacion.jsx";
import MiPerfil from "./paginas/configuracion/MiPerfil.jsx";
import CopiaSeguridad from "./paginas/configuracion/CopiaSeguridad.jsx";
import SoporteTecnico from "./paginas/configuracion/SoporteTecnico.jsx";
import Publicaciones from "./paginas/Publicaciones.jsx";
import AgregarProducto from "./paginas/productos/AgregarProducto.jsx";
import DetalleProducto from "./paginas/productos/DetalleProducto.jsx";
import CargarPlanos from "./paginas/planos/CargarPlanos.jsx";
import DetalleFactura from "./paginas/cartera/DetalleFactura.jsx";
import NuevoPago from "./paginas/cartera/NuevoPago.jsx";
import CarteraDetalleCliente from "./paginas/cartera/DetalleCliente.jsx";
import NuevoItem from "./paginas/produccion/NuevoItem.jsx";
import OrdenProduccion from "./paginas/produccion/OrdenProduccion.jsx";
import RevisarProduccion from "./paginas/produccion/RevisarProduccion.jsx";
import AdministrarUsuarios from "./paginas/configuracion/AdministrarUsuarios.jsx";
import NuevoDespacho from "./paginas/despachos/NuevoDespacho.jsx";
import HacerDespachos from "./paginas/despachos/HacerDespacho.jsx";
import Despachados from "./paginas/despachos/Despachados.jsx";
import ListadoDocumentacion from "./paginas/documentar/ListadoDocumentacion.jsx";
import CrearDocumento from "./paginas/documentar/CrearDocumento.jsx";
import Logout from "./paginas/Logout.jsx";
import PedidosArchivados from "./paginas/pedidos/Archivados.jsx";
import VerDespachosArchivados from "./paginas/despachos/Archivados.jsx";
import ParametrosCartera from "./paginas/cartera/Parametros.jsx";
import DetallePedido from "./paginas/pedidos/DetallePedidos.jsx";
import Recovery from "./paginas/Recovery.jsx";
import EditarUsuario from "./paginas/configuracion/EditarUsuario.jsx";
import AgregarUsuario from "./paginas/configuracion/AgregarUsuario.jsx";
import GaleriaProductos from "./paginas/productos/Galeria.jsx";
import EditarPedido from "./paginas/pedidos/EditarPedido.jsx";
import ModoPlanta from "./paginas/planta/ModoPlanta.jsx";
import DetallePedidoFinalizado from "./paginas/pedidos/DetallePedidoFinalizado.jsx";
import EditarDespacho from "./paginas/despachos/EditarDespacho.jsx";
import ListadoDocumentacionRealizada from "./paginas/documentar/Documentados.jsx";
import DetalleDespachosPedido from "./paginas/despachos/DetalleDespachosPedido.jsx";

let permisos = localStorage.getItem('PERMISOS');
if(permisos!='-1' && permisos!='' && permisos!=null){
  permisos = permisos.split(",");
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Inicio />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/logout',
        element: <Logout />
    },
    {
        path: '/recovery/:token',
        element: <Recovery />
    },
    {
        path: '/modo-planta',
        element: <ModoPlanta />
    },
    {
        path: '/',
        element: <General permisosAsignados={permisos}/>,
        children: [
            {
                path: '/home',
                element: <Home />
            },
            {
                path: '/publicaciones-todas',
                element: <Publicaciones />
            },
            {
                path: '/clientes-todos',
                element: <TodosClientes permisosAsignados={permisos}/>
            }, 
            {
                path: '/clientes-mios',
                element: <MisClientes permisosAsignados={permisos}/>
            }, 
            {
                path: '/clientes-parametros',
                element: <ParametrosClientes permisosAsignados={permisos}/>
            }, 
            {
                path: '/clientes-resumen',
                element: <ResumenClientes />
            },
            {
                path: '/clientes-detalles/:id',
                element: <DetalleCliente permisosAsignados={permisos}/>
            },
            {
                path: '/clientes-agregar',
                element: <AgregarCliente permisosAsignados={permisos} />
            },
            {
                path: '/productos-todos',
                element: <TodosProductos permisosAsignados={permisos}/>
            },
            {
                path: '/productos-agregar',
                element: <AgregarProducto permisosAsignados={permisos}/>
            },
            {
                path: '/productos-parametros',
                element: <ParametrosProductos permisosAsignados={permisos}/>
            },
            {
                path: '/productos-detalles/:id',
                element: <DetalleProducto permisosAsignados={permisos}/>
            },
            {
                path: '/productos-resumen',
                element: <ResumenProductos />
            },
            {
                path: '/productos-galeria/:id',
                element: <GaleriaProductos permisosAsignados={permisos}/>
            },
            {
                path: '/planos-todos',
                element: <TodosPlanos permisosAsignados={permisos}/>
            },
            {
                path: '/planos-cargar/:id',
                element: <CargarPlanos permisosAsignados={permisos}/>
            },
            {
                path: '/planos-resumen',
                element: <ResumenPlanos />
            },
            {
                path: '/pedidos-nuevo',
                element: <NuevoPedido permisosAsignados={permisos}/>
            },
            {
                path: '/pedidos-todos',
                element: <TodosPedidos permisosAsignados={permisos}/>
            },
            {
                path: '/pedidos-mios',
                element: <MisPedidos permisosAsignados={permisos}/>
            },
            {
                path: '/pedidos-resumen',
                element: <ResumenPedidos />
            },
            {
                path: '/pedidos-archivados',
                element: <PedidosArchivados permisosAsignados={permisos}/>
            },
            {
                path: '/editar-pedido/:id',
                element: <EditarPedido permisosAsignados={permisos}/>
            },
            {
                path: '/detalle-pedido/:id',
                element: <DetallePedido permisosAsignados={permisos}/>
            },
            {
                path: '/detalle-pedido-finalizado/:id',
                element: <DetallePedidoFinalizado permisosAsignados={permisos}/>
            },
            {
                path: '/planta-ver-planos',
                element: <VerPlanos />
            },
            {
                path: '/planta-estado',
                element: <EstadoPlanta permisosAsignados={permisos}/>
            },
            {
                path: '/planta-resumen',
                element: <ResumenPlanta />
            },
            {
                path: '/produccion-control',
                element: <ControlProduccion permisosAsignados={permisos}/>
            },
            {
                path: '/produccion-resumen',
                element: <ResumenProduccion />
            },
            {
                path: '/produccion-nuevo-item',
                element: <NuevoItem />
            },
            {
                path: '/produccion-orden',
                element: <OrdenProduccion permisosAsignados={permisos}/>
            },
            {
                path: '/produccion-revisar',
                element: <RevisarProduccion permisosAsignados={permisos}/>
            },
            {
                path: '/despachos-ver',
                element: <VerDespachos permisosAsignados={permisos}/>
            },
            {
                path: '/despachos-nuevo',
                element: <NuevoDespacho permisosAsignados={permisos}/>
            },
            {
                path: '/despachos-despachados/:id',
                element: <Despachados permisosAsignados={permisos}/>
            },
            {
                path: '/despachos-hacer/:id',
                element: <HacerDespachos permisosAsignados={permisos}/>
            },
            {
                path: '/despachos-archivados',
                element: <VerDespachosArchivados permisosAsignados={permisos}/>
            },
            {
                path: '/editar-despacho/:id',
                element: <EditarDespacho permisosAsignados={permisos}/>
            },
            {
                path: '/despachos-realizados/:id',
                element: <DetalleDespachosPedido permisosAsignados={permisos}/>
            },
            {
                path: '/documentar-listado',
                element: <ListadoDocumentacion permisosAsignados={permisos}/>
            },
            {
                path: '/documentos-realizados',
                element: <ListadoDocumentacionRealizada permisosAsignados={permisos}/>
            },
            {
                path: '/documentar-crear/:id',
                element: <CrearDocumento permisosAsignados={permisos}/>
            },
            {
                path: '/precios-lista',
                element: <ListaPrecios permisosAsignados={permisos}/>
            },
            {
                path: '/precios-crear-lista',
                element: <CrearLista />
            },
            {
                path: '/precios-resumen',
                element: <ResumenPrecios />
            },
            {
                path: '/inventario-ver',
                element: <VerInventario permisosAsignados={permisos}/>
            },
            {
                path: '/inventario-agregar-item',
                element: <AgregarItems permisosAsignados={permisos}/>
            },
            {
                path: '/inventario-retirar-item',
                element: <RetirarItems />
            },
            {
                path: '/cartera-parametros',
                element: <ParametrosCartera permisosAsignados={permisos}/>
            },
            {
                path: '/cartera-pendiente',
                element: <CarteraPendiente permisosAsignados={permisos}/>
            },
            {
                path: '/cartera-historial',
                element: <HistorialCartera permisosAsignados={permisos}/>
            },
            {
                path: '/cartera-detalle-factura/:id',
                element: <DetalleFactura permisosAsignados={permisos}/>
            },
            {
                path: '/cartera-nuevo-pago/:id',
                element: <NuevoPago permisosAsignados={permisos}/>
            },
            {
                path: '/cartera-detalle-cliente/:id',
                element: <CarteraDetalleCliente permisosAsignados={permisos}/>
            },
            {
                path: '/web-articulos-publicados',
                element: <ArticulosPublicados permisosAsignados={permisos}/>
            },
            {
                path: '/web-informacion',
                element: <Informacion permisosAsignados={permisos}/>
            },
            {
                path: '/configuracion-administrar-usuarios',
                element: <AdministrarUsuarios permisosAsignados={permisos}/>
            },
            {
                path: '/configuracion-mi-perfil',
                element: <MiPerfil />
            },
            {
                path: '/configuracion-editar-usuario/:id',
                element: <EditarUsuario permisosAsignados={permisos}/>
            },
            {
                path: '/configuracion-agregar-usuario',
                element: <AgregarUsuario permisosAsignados={permisos}/>
            },
            {
                path: '/configuracion-copia-seguridad',
                element: <CopiaSeguridad permisosAsignados={permisos}/>
            },
            {
                path: '/configuracion-soporte',
                element: <SoporteTecnico />
            }
        ]
    },
    {
        path: '*',
        element: <NotFound />
    }
]);

export default router;
