import React, { useEffect, useState, createRef } from 'react'
import { useParams } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Swal from 'sweetalert2';

function DetallePedido(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('pedidos_view') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [pedidos, setPedidos] = useState([]);
  const [total, setTotal] = useState([]);
  const [notas, setNotas] = useState([]);

  const [cliente, setCliente] = useState([]);
  const [negociacionCliente, setNegociacionCliente] = useState([]);
  const [negociacionPedido, setNegociacionPedido] = useState([]);

  const notaRef = createRef();
  const id = useParams();

  const consultarPedidos = () => {
    let payload = {
      id_pedido: id.id
    }
    axiosClient.post('/consultar-pedido-detalle', payload)
    .then(({data})=> {
      setPedidos(data.items)
      setTotal(data.total)
      setCliente(data.cliente[0].razon_social+" - "+data.cliente[0].nombre);
      setNegociacionCliente(data.cliente[0].negociacion)
      setNegociacionPedido(data.cliente[0].negociacionPedido)
    })
  }

  const verNotas = (id_registro) => {
    let payload = {
      id_registro
    }
    localStorage.setItem('id_pedido', id_registro);
    axiosClient.post('/notas-pedido-item', payload)
    .then(({data})=> {
      setNotas(data)
    })
  }

  const onSubmitNota = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_pedido_item: localStorage.getItem("id_pedido"),
      nota: notaRef.current.value
    }
    axiosClient.post("/agregar-nota-pedido-item", payload)
      .then(({ data_ }) => {
        //verNotas(localStorage.getItem("id_pedido"))
        window.location = "/detalle-pedido/"+id.id;
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  const actualizarEstado = (id_registro) => {
    Swal.fire({ 
      text: "Confirmar archivar el registro", 
      icon: "info", 
      showCancelButton: !0, 
      confirmButtonText: "Enviar", 
      confirmButtonColor: '#86E736', 
      cancelButtonText: "Cancelar",
    }).then((e) => {
      if(e.isConfirmed){
        let payload = {
          id_registro,
          estado: '4',
          tabla: 'pedidos_item',
          id_pedido: id.id
        }
        axiosClient.post("/enviar-estado-tabla", payload)
          .then(({ data }) => {
          window.location = "/detalle-pedido/"+id.id;
        })
        .catch((err) => {
          const response = err.response;
          console.log(response);
        });
      }
    });
  };

  useEffect(() => {
    consultarPedidos();
  },[])

  return (
    <main>
      <div className='container-fluid'>
      <div className="row">
          <div className="col-12">
            <h1>Detalle pedido</h1>
            <div className="">
              <strong>Cliente: </strong>{cliente}<br></br>
              <strong>Negociación del cliente: </strong>{negociacionCliente}<br></br>
              <strong>Negociación del pedido: </strong>{negociacionPedido}<br></br><br></br>
            </div>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <table id="datatableRow" className="data-table responsive nowrap cont_tabla_export" data-order="[[ 1, &quot;desc&quot; ]]">
              <thead>
                <tr>
                  <th>Pedido</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Valor Unitario</th>
                  <th>Valor Total</th>
                  <th>Estado</th>
                  <th>Notas</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {pedidos.map((registro, i) => (
                <tr key={i}>
                  <td>
                    <p className="list-item-heading">{registro.id}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.codigo}
                      <br /><span className="text-muted">{registro.titulo}</span></p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.cantidad}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.precio_formato}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.precio_total}</p>
                  </td>
                  <td>
                    <label className={registro.estado_clase}>{registro.estado_nombre}</label>
                  </td>
                  <td>
                    <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" onClick={() => verNotas(registro.id)}>
                      Notas
                    </button>
                  </td>
                  <td>
                    <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Accion
                    </button>
                    <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupDrop1"
                    >
                        <a className="dropdown-item activatePointer" onClick={() => actualizarEstado(registro.id)}>Archivar</a>
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Notas:
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <h5 className="card-title" />
                <div>
                {notas.map((value, key) => (
                  <div key={key} className="d-flex flex-row mb-3 border-bottom justify-content-between">
                    <a>
                        <img
                           src={value.users.image}
                          alt={value.users.name}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                      </a>
                      <div className="pl-3 flex-grow-1">
                        <p className="list-item-heading mb-0 ">
                          {value.users.name} &nbsp;
                          <span className="text-muted mb-0 text-small">
                            {value.fecha}
                            <i className="simple-icon-clock mr-1" />
                            {value.hora}
                          </span>{" "}
                        </p>
                        <p className="mt-3">
                          <i className="simple-icon-bubble mr-1 heading-icon" />
                          {value.nota}
                        </p>
                      </div>
                  </div>
                  ))}
                  <form onSubmit={handleSubmit(onSubmitNota)}>
                    <div className="comment-contaiener">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agregar Nota"
                          ref={notaRef}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="submit">
                            <span className="d-inline-block">Guardar</span>
                            <i className="simple-icon-arrow-right ml-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

    </main>
  )
}

export default DetallePedido;