import React from "react";

function ResumenPlanta() {
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Resumen</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="mb-4">Line Chart</h5>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="salesChart" />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">No Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="salesChartNoShadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="mb-4">Bar Chart</h5>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="productChart" />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">No Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="productChartNoShadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="mb-4">Area Chart</h5>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="areaChart" />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">No Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="areaChartNoShadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="mb-4">Scatter Chart</h5>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="scatterChart" />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">No Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="scatterChartNoShadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="mb-4">Radar Chart</h5>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="radarChart" />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">No Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="radarChartNoShadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="mb-4">Polar Chart</h5>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="polarChart" />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">No Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="polarChartNoShadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="mb-4">Doughnut Chart</h5>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="categoryChart" />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">No Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="categoryChartNoShadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="mb-4">Pie Chart</h5>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="pieChart" />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <h6 className="mb-4">No Shadow</h6>
                    <div className="chart-container chart">
                      <canvas id="pieChartNoShadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResumenPlanta;
