import React from "react";

function SoporteTecnico() {
  const onSubmit = (ev) => {
    ev.preventDefault();
    window.location = "/home";
  };
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Este es su canal de soporte estándar, si tiene un soporte de emergencia contactar vía whatsapp o teléfono</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row">
          {/*<div className="col-12 col-md-12 col-xl-7 col-left">
            <div className="card mb-4 justify-content-center">
              <div className="lightbox">
                <img
                  alt="detalle"
                  src="./img/fotoCliente.jpeg"
                  className="responsive border-0 card-img-top mb-3"
                />
              </div>
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={onSubmit}
                >
                  <div className="form-group has-float-label">
                    <span>Menús</span>
                    <select
                      className="form-control select2-single"
                      name="jQueryTopLabelsState"
                      required
                      data-width="100%"
                    >
                      <option />
                      <optgroup label="Clientes">
                        <option value="AK">Todos los Clientes</option>
                        <option value="HI">Mis Clientes</option>
                        <option value="HI">Parámetros</option>
                      </optgroup>
                      <optgroup label="Productos">
                        <option value="CA">Todos los Prodcutos</option>
                        <option value="NV">Parámetros</option>
                      </optgroup>
                      <optgroup label="Planos">
                        <option value="AZ">Todos los Planos</option>
                      </optgroup>
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <label>Incidencia a Reportar</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      name="jQueryDetail"
                      required
                      defaultValue={""}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block mb-1"
                  >
                    ENVIAR REPORTE
                  </button>
                </form>
              </div>
            </div>
          </div>*/}
          <div className="col-12 col-md-12 col-xl-5 col-right">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Puedes escribirnos a: corporacionrubiano@gmail.com</h5>
                <div className="mt-5">
                  <a href="mailto:corporacionrubiano@gmail.com" target="_blank">
                    <img
                      alt="detalle"
                      src="./img/email.png"
                      className="responsive border-0 card-img-top mb-3"
                      style={{width: "25%"}}
                      />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 col-xl-5 col-right">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Canal de Whatsapp de Soporte: +57 304 403 1840</h5>
                <div className="mt-5">
                  <a href="https://wa.me/573044031840" target="_blank">
                    <img
                      alt="detalle"
                      src="./img/whatsapp.png"
                      className="responsive border-0 card-img-top mb-3"
                      style={{width: "25%"}}
                      />
                  </a>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </main>
  );
}

export default SoporteTecnico;
