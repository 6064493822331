import React, { useEffect, useState } from "react";
import axiosClient from '../../axios-client.js'
import Swal from "sweetalert2";

function AdministrarUsuarios(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('configuracion_view') && permisos!='-1'){
    window.location = '/home';
  }

  const [usuarios, setUsuarios] = useState([]);

  const consultarUsuarios = () => {
    axiosClient.get('/obtener-usuarios')
    .then(({data}) => {
      setUsuarios(data)
    })
  }

  const actualizarRegistro = (id) => {
    window.location = "/configuracion-editar-usuario/"+id
  }

  const eliminarRegistro = (id) => {
    let payload = {
      tabla: "users",
      estado: "4",
      id_registro: id
    }
    Swal.fire({ 
      text: "Confirmar eliminación de registro", 
      icon: "info", 
      showCancelButton: !0, 
      confirmButtonText: "Enviar", 
      confirmButtonColor: '#008E8A', 
      cancelButtonText: "Cancelar", 
      cancelButtonColor: '#d70021' 
    }).then((e) => {
      if(e.isConfirmed){
        axiosClient.post('/enviar-estado-tabla', payload)
        .then(({data})=>{
          window.location = "/configuracion-administrar-usuarios"
        })
      }
    });
  }

  const irAgregar = () => {
    window.location = "/configuracion-agregar-usuario";
  }
  

  useEffect(() => {
    consultarUsuarios()
  }, []);
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Administrar Usuarios</h1>
            <div className="top-right-button-container">
              <div className="btn-group">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={()=>irAgregar()}
                >
                  AGREGAR
                </button>
              </div>
            </div>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <br></br><br></br>
            <table
              id=""
              className="data-table responsive nowrap cont_tabla"
              data-order='[[ 0, "asc" ]]'
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NOMBRE</th>
                  <th>CORREO</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {usuarios.map((registros,i)=>(
                <tr key={i}>
                  <td>
                    <div className="icon-cards-row">
                      <p className="lead mt-2">{registros.id}</p>
                    </div>
                  </td>
                  <td>
                    <p className="list-item-heading">{registros.name}</p>
                  </td>
                  <td>
                    <p className="text-muted">{registros.email}</p>
                  </td>
                  <td>
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => actualizarRegistro(registros.id)}
                    >
                      Editar
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={()=>eliminarRegistro(registros.id)}
                    >
                      Borrar
                    </button>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AdministrarUsuarios;
