import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import axiosClient from "../../axios-client";

function ParametrosCartera(props) {

  let permisos = props.permisosAsignados;
  if(permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [tipoPago, setTipoPago] = useState([]);

  const consultarTipoPago = () => {
    let payload = {
      tabla: 'cartera',
      id_group: '1'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setTipoPago(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const onSubmitTipo = (data) => {
    let payload = {
      id_group: data.id_group,
      tabla: data.tabla,
      texto_parametro: data.texto_parametro,
      id_user: localStorage.getItem("ID_USER")
    }
    axiosClient.post("/agregar-parametro", payload)
      .then(({ data }) => {
      window.location = "/cartera-parametros";
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  };


  const eliminarElemento = (id_registro) => {
    Swal.fire({ 
      text: "Confirmar eliminación de registro", 
      icon: "info", 
      showCancelButton: !0, 
      confirmButtonText: "Enviar", 
      confirmButtonColor: '#008E8A', 
      cancelButtonText: "Cancelar", 
      cancelButtonColor: '#d70021' 
    }).then((e) => {
      if(e.isConfirmed){
        let payload = {
          id_registro
        }
        axiosClient.post("/eliminar-parametro", payload)
          .then(({ data }) => {
          window.location = "/cartera-parametros";
        })
        .catch((err) => {
          const response = err.response;
          console.log(response);
        });
      }
    });
  };

  const editarElemento = (id_registro, nombre) => {
    Swal.fire({
      text: "Agregue un nuevo valor para '"+nombre+"'",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Editar",
      confirmButtonColor: "#86E736",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: async (valor) => {
        let payload = {
          id_registro,
          nombre: valor
        }
        axiosClient.post('/editar-parametro', payload)
        .then(({data})=>{
          window.location = "/cartera-parametros";
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  useEffect(() => {
    consultarTipoPago();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Parámetros</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 col-md-12 mb-4">
            <div className="card">
              <div className="card-footer">
                <form onSubmit={handleSubmit(onSubmitTipo)}>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Agregar parámetro" {...register("texto_parametro")}
                    />
                    <div className="input-group-append">
                      <input type="hidden" name="id_group" value="1" {...register("id_group")}/>
                      <input type="hidden" name="tabla" value="cartera" {...register("tabla")}/>
                      <button className="btn btn-secondary" type="submit">
                        <span className="d-inline-block">Guardar</span>
                        <i className="simple-icon-arrow-right ml-2" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-body">
                <h5 className="card-title">Tipo de pago</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    { tipoPago.map((registro, i) =>(
                    <tr key={i}>
                      <th className="heading-icon" scope="row">
                        {registro.id}
                      </th>
                      <td>{registro.nombre}</td>
                      <td className="activatePointer">
                        <i className="simple-icon-pencil align-middle p-2 heading-icon" onClick={() => editarElemento(registro.id, registro.nombre)}/>
                        <a onClick={() => eliminarElemento(registro.id)}>
                          <i className="simple-icon-trash align-middle heading-icon" />
                        </a>
                      </td>
                    </tr>
                    )) }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ParametrosCartera;
