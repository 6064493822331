import React from 'react'

function RetirarItems() {
  return (
    <main>
      <div className="container-fluid">
        Retirar Items
      </div>
    </main>
  )
}

export default RetirarItems