import React, {createRef, useState} from "react";
import axiosClient from "../../axios-client";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function AgregarUsuario(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('configuracion_view') && permisos!='-1'){
    window.location = '/home';
  }
  const {
    handleSubmit,
    formState: {  },
  } = useForm();

  const [selectedFile, setSelectedFile] = useState(null);

  const nombreRef = createRef();
  const emailRef = createRef();
  const identificacionRef = createRef();
  const celularRef = createRef();
  const passwordRef = createRef();

  const onSubmit = (payload) => {
    axiosClient
      .post(
        `/crear-usuario`,
        {
          file: selectedFile,
          nombre: nombreRef.current.value,
          identificacion: identificacionRef.current.value,
          celular: celularRef.current.value,
          email: emailRef.current.value,
          password: passwordRef.current.value
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        if(data.icon=='error'){
            Swal.fire({
                icon: data.icon,
                text: data.message
            })
        }else{
            window.location = "/configuracion-administrar-usuarios";
        }
      });
  };

  const styles = { 
    transform: `translateX(-50%)`,
    height: `200px`,
    border: `3px solid white`,
    position: `absolute`,
    left: `50%`,
    zIndex: `1`,
    top: `-125px`
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Agregar usuario</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-xl-7">
            <div className="card mt-5 justify-content-center">
              <div className="card-body" style={{ marginTop: 50 }}>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <div className="form-group has-float-label">
                    <label>Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="jQueryName"
                      required
                      ref={nombreRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Número de Identificación</label>
                    <input
                      type="number"
                      className="form-control"
                      name="jQueryAge"
                      ref={identificacionRef}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Celular</label>
                    <input
                      type="text"
                      className="form-control"
                      name="jQueryName"
                      required
                      ref={celularRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>E-mail</span>
                    <input
                      type="email"
                      className="form-control"
                      name="jQueryTopLabelsEmail"
                      required
                      ref={emailRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      name="jQueryName"
                      ref={passwordRef}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label
                      className="btn btn-outline-primary btn-upload"
                      htmlFor="inputImage"
                      title="Cargue archivo de imagen"
                    >
                      <input
                        id="inputImage"
                        type="file"
                        className="sr-only"
                        accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                        required
                      />
                      Agregar foto de perfil
                    </label>
                    &nbsp;<label>{selectedFile ? selectedFile.name : ''}</label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block mb-1"
                  >
                    Crear Perfil
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AgregarUsuario;
