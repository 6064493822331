import React, {createRef, useState} from "react";
import axiosClient from "../../axios-client";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

function MiPerfil() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedFile, setSelectedFile] = useState(null);
  const [nombre, setNombre] = useState([]);
  const [email, setEmail] = useState([]);
  const [identificacion, setIdentificacion] = useState([]);
  const [celular, setCelular] = useState([]);
  const [imagen, setImagen] = useState([]);

  const id = useParams();

  const nombreRef = createRef();
  const emailRef = createRef();
  const identificacionRef = createRef();
  const celularRef = createRef();
  const passwordRef = createRef();

  const consultarDataUsuario = () => {
    let payload = {
      id_user: localStorage.getItem("ID_USER")
    }
    axiosClient.post('/obtener-usuario', payload)
    .then(({data})=>{
      setNombre(data[0].name)
      setEmail(data[0].email)
      setIdentificacion(data[0].identificacion)
      setCelular(data[0].celular)
      setImagen(data[0].image)
    })
  }

  const onSubmit = (payload) => {
    axiosClient
      .post(
        `/actualizar-usuario`,
        {
          file: selectedFile,
          id_user: localStorage.getItem("ID_USER"),
          nombre: nombreRef.current.value,
          identificacion: identificacionRef.current.value,
          celular: celularRef.current.value,
          email: emailRef.current.value,
          password: passwordRef.current.value
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        window.location = "/configuracion-mi-perfil";
      });
  };

  const styles = { 
    transform: `translateX(-50%)`,
    height: `200px`,
    border: `3px solid white`,
    position: `absolute`,
    left: `50%`,
    zIndex: `1`,
    top: `-125px`
  };

  useState(() => {
    consultarDataUsuario()
  }, [])
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Editar usuario</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-xl-7" style={{ marginTop: 100 }}>
            <div className="card mt-5 justify-content-center">
              <img
                alt="Profile"
                src={imagen}
                className="img-thumbnail card-img"
                style={styles}
              />
              <div className="card-body" style={{ marginTop: 50 }}>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <div className="form-group has-float-label">
                    <label>Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="jQueryName"
                      defaultValue={nombre}
                      required
                      ref={nombreRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Número de Identificación</label>
                    <input
                      type="number"
                      className="form-control"
                      name="jQueryAge"
                      defaultValue={identificacion}
                      ref={identificacionRef}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Celular</label>
                    <input
                      type="text"
                      className="form-control"
                      name="jQueryName"
                      defaultValue={celular}
                      required
                      ref={celularRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>E-mail</span>
                    <input
                      type="email"
                      className="form-control"
                      name="jQueryTopLabelsEmail"
                      defaultValue={email}
                      required
                      ref={emailRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Nueva Contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      name="jQueryName"
                      ref={passwordRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label
                      className="btn btn-outline-primary btn-upload"
                      htmlFor="inputImage"
                      title="Cargue archivo de imagen"
                    >
                      <input
                        id="inputImage"
                        type="file"
                        className="sr-only"
                        accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                      Cambiar foto de perfil
                    </label>
                    &nbsp;<label>{selectedFile ? selectedFile.name : ''}</label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block mb-1"
                  >
                    Actualizar Perfil
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default MiPerfil;
