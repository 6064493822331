import React, { useState, useEffect, createRef } from "react";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import axiosClient from "../../axios-client";
import { useParams } from "react-router-dom";
import $ from 'jquery';

function ListaPrecios(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('precios_view') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [productos, setProductos] = useState([]);
  const [historico, setHistoricoPrecio] = useState([]);
  const [codigo, setCodigo] = useState([]);

  const dataProductos = () =>{
    axiosClient.get('/obtener-productos')
    .then(({ data }) => {
      setProductos(data);
    }).catch(function (error) {
        console.log(error);
    });
  }

  const getHistoricoPrecio = (id_producto) => {
    let payload = {
      id_producto
    }
    axiosClient.post('/historico-precio-producto', payload)
      .then(({ data }) => {
        setHistoricoPrecio(data.data);
        setCodigo(data.codigo)
      }).catch(function (error) {
          console.log(error);
      });
  }

  const cambiarPrecio = (id_producto) => {
    if($("#"+id_producto).val()){
      let payload = {
        id_user: localStorage.getItem("ID_USER"),
        precio: $("#"+id_producto).val(),
        id_producto
      }
      axiosClient.post('/actualizar-precio-producto', payload)
      .then(({ data }) => {
        window.location = "/precios-lista";
      }).catch(function (error) {
          console.log(error);
      });
    }else{
      Swal.fire({
        icon: 'info',
        text: 'El campo "precio" no puede estar vacío'
      })
    }
  }

  useEffect(() => {
    dataProductos();
  }, []);


  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Lista de Precios</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <table className="data-table cont_tabla responsive">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Código</th>
                      <th>Producto</th>
                      <th>Precio Actual</th>
                      <th>Histórico</th>
                      <th>Nuevo Precio</th>
                    </tr>
                  </thead>
                  <tbody>
                    { productos.map((registro, i) => (
                    <tr key={i}>
                      <td>{registro.id}</td>
                      <td>{registro.codigo}</td>
                      <td>{registro.titulo}</td>
                      <td>{registro.precio_formato}</td>
                      <td>
                        <button type="button" className="btn btn-primary btn-xs mb-1" data-toggle="modal" data-target="#exampleModal" onClick={() => getHistoricoPrecio(registro.id)}>
                          Ver Histórico
                        </button>
                      </td>
                      <td>
                      {permisos.includes('precios_add') || permisos=='-1' ?
                        <div className="row">
                          <div className="col-lg-8">
                            <input type="text" className="form-control" id={registro.id} placeholder="$"/>
                          </div>
                          <div className="col-lg-2">
                            <button type="button" title="Guardar precio" onClick={() => cambiarPrecio(registro.id)} className="btn btn-success btn-xs"><i className="glyph-icon simple-icon-check"></i></button>
                          </div>
                        </div>
                      : ''}
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Histórico de Precios
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Código: {codigo}</th>
                    <th>Valores</th>
                  </tr>
                </thead>
                <tbody>
                { historico.map((registro, i) => (
                    <tr key={i}>
                    <td>{registro.fecha}</td>
                    <td>{registro.precio_formato}</td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ListaPrecios;
