import React, { createRef, useEffect, useState } from 'react';
import axiosClient from "../axios-client.js";
import { Navigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

function Recovery() {

  const token = localStorage.getItem('ACCESS_TOKEN');

  const tokenRecovery = useParams();

  if (token) {
    window.location.href = "/home";
  }

  const [email, setEmail] = useState([]);
  const emailRef = createRef();
  const passwordRef = createRef();

  const onSubmit = ev => {
    ev.preventDefault();
    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      token: tokenRecovery.token
    }
    axiosClient.post('/auth/new-password', payload)
      .then(({ data }) => {
        Swal.fire({
            icon: 'success',
            text: data.message
        })
        setTimeout(() => {
            window.location = "/login";
        }, 2500);
      })
  }

  const recoveryPassword = () => {
    let payload = {
        token: tokenRecovery.token
    }
    axiosClient.post('/auth/recovery-token', payload)
    .then(({data}) => {
        setEmail(data[0].email)
    })
  }

  const styles = { 
    textAlign: `center`,
  };

  const styles2 = {
    backgroundColor: `#ddd`
  }

  useEffect(() => {
    recoveryPassword()
  }, [])

  return (
    <div className="background no-footer">
      <div className="fixed-background" />
      <main>
        <div className="container">
          <div className="row h-100">
            <div className="col-12 col-md-10 mx-auto my-auto">
              <div className="card auth-card">
                <div className="position-relative image-side ">
                  <p className=" text-white h2">MAGIC IS IN THE DETAILS</p>
                  <p className="white mb-0">
                    Please use your credentials to login.
                    <br />If you are not a member, please
                    <a href="#" className="white"> register</a>.
                  </p>
                </div>
                <div className="form-side">
                  <a href="#">
                    <span className="logo-single" />
                  </a>
                  <h6 className="mb-4">Recuperar contraseña</h6>
                  <form onSubmit={onSubmit} className='tooltip-right-bottom'>
                    <div className="input-group mb-3">
                      <input ref={emailRef} type="email" className="form-control" placeholder="Email" required value={email} readonly disabled style={styles2}/>
                      <div className="input-group-append">
                      </div>
                    </div>
                    <div className="form-group has-float-label">
                        <label>Asignar nueva contraseña</label>
                        <input ref={passwordRef} type="password" className="form-control" required />
                    </div>
                    <div className="row">

                      <div className="col-12">
                        <button type="submit" className="btn btn-primary btn-block">Recuperar</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Recovery