import axios from 'axios';

//const base_url = 'http://localhost/eva/eva-slim/v1'; //Dominio Desarrollo
const base_url = 'https://eva.induman.co/api/v1'; //Dominio Producción

const axiosClient = axios.create({
    baseURL: base_url
})

axiosClient.interceptors.request.use((config) => {
    config.headers['X-API-KEY'] = `66d243ea-7695-444d-853e-835e07c3a983`
    return config;
})

axiosClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    try {
        const {response} = error;
        if(response.status === 401) {
            localStorage.removeItem('ACCESS_TOKEN');
            window.location.href = "/login";
        }
    } catch(e) {
        console.log(e)
    }
    

    throw error;
})
export default axiosClient;