import React from 'react'

function VerPlanos() {
  return (
    <main>
      <div className="container-fluid">
        Ver Planos
      </div>
    </main>
  )
}

export default VerPlanos