import React, { useState, useEffect } from "react";
import axiosClient from "../../axios-client";
import { useForm } from "react-hook-form";

function AgregarCliente(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('clientes_add') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [ciudades, setCiudades] = useState([]);
  const [ciudadSeleccionada, setCiudadSeleccionada] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState([]);

  const handleChange = (event) => {
    setCiudadSeleccionada(event.target.value);
  };

  const handleChange2 = (event) => {
    setTipoDocumentoSeleccionado(event.target.value);
  };

  const onSubmit = (data) => {
    data["tipo_documento"] = tipoDocumentoSeleccionado;
    data["ciudad"] = ciudadSeleccionada;
    data["id_user"] = localStorage.getItem("ID_USER");
    axiosClient.post("/agregar-cliente", data)
      .then(({ data }) => {
      window.location = "/clientes-todos";
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  };

  const consultarCiudades = () => {
    let payload = {
      tabla: 'ciudades',
      id_group: '1'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setCiudades(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const consultarTipoDocumento = () => {
    let payload = {
      tabla: 'tipo_documento',
      id_group: '2'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setTipoDocumento(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  useEffect(() => {
    consultarCiudades();
    consultarTipoDocumento();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Nuevo Registro de Cliente</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-xl-7 ">
            <div className="card mb-4 justify-content-center">
              <div className="lightbox">
                <img
                  alt="detalle"
                  src="./img/fotoCliente.jpeg"
                  className="responsive border-0 card-img-top mb-3"
                />
              </div>
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group has-float-label">
                    <label>Empresa</label>
                    <input
                      type="text"
                      className="form-control"
                      name="empresa"
                      {...register("empresa")}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre"
                      {...register("nombre")}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Tipo de documento</label>
                    <select
                      className="form-control"
                      required
                      defaultValue={tipoDocumentoSeleccionado}
                      onChange={handleChange2}
                    >
                      <option value=""></option>
                      {tipoDocumento.map((tipo) =>(
                        <option key={tipo.id} value={tipo.id}>
                          {tipo.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <label>Número de Documento</label>
                    <input
                      type="text"
                      className="form-control"
                      name="numeroDocumento"
                      {...register("numero_documento")}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Dirección</label>
                    <input
                      type="text"
                      className="form-control"
                      name="direccion"
                      {...register("direccion")}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>Ciudad</span>
                    <select
                      className="form-control"
                      required
                      defaultValue={ciudadSeleccionada}
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      {ciudades.map((ciudad) =>(
                        <option key={ciudad.id} value={ciudad.id}>
                          {ciudad.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <label>Celular</label>
                    <input
                      type="text"
                      className="form-control"
                      name="celular"
                      {...register("celular")}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>Correo</span>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      {...register("email")}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Negociación</label>
                    <input
                      type="text"
                      className="form-control"
                      name="negociacion"
                      {...register("negociacion")}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Notas del Cliente</label>
                    <textarea
                      className="form-control"
                      rows={2}
                      name="notasCliente"
                      {...register("notas_cliente")}
                      required
                      defaultValue={""}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block mb-1"
                  >
                    CREAR CLIENTE
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AgregarCliente;
