import React, { createRef, useState } from 'react';
import axiosClient from "../axios-client.js";
import { Navigate } from "react-router-dom";
import Swal from 'sweetalert2';

function Login() {

  const token = localStorage.getItem('ACCESS_TOKEN');

  if (token) {
    window.location.href = "/home";
  }

  const emailRef = createRef();
  const passwordRef = createRef();
  const [errors, setErrors] = useState('');

  const onSubmit = ev => {
    ev.preventDefault();
    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value
    }
    axiosClient.post('/auth/login', payload)
      .then(({ data }) => {
        if (data.message) {
          setErrors({
            pass: [data.message]
          });
        } else {
          localStorage.setItem('ACCESS_TOKEN', data.token);
          localStorage.setItem('ID_USER', data.id_user);
          localStorage.setItem('NAME_USER', data.name_user);
          localStorage.setItem('IMAGE_USER', data.image_user);
          localStorage.setItem('PERMISOS', data.permisos);
          window.location = '/home';
        }
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
        if (response && response.status === 422) {
          if (response.data.errors) {
            setErrors(response.data.errors)
          } else {
            console.log(response.data.message)
            setErrors({
              email: [response.data.message]
            })
          }
        }
      })
  }

  const recoveryPassword = () => {
    if(emailRef.current.value){
      let payload = {
        email: emailRef.current.value
      }
      axiosClient.post('/auth/recovery', payload)
      .then(({data}) => {
        Swal.fire({
          icon: data.icon,
          text: data.text
        })
      })
    }else{
      Swal.fire({
        icon: 'info',
        text: 'Escriba su correo para recuperar la contrasñea'
      })
    }
  }

  const styles = { 
    textAlign: `center`,
  };

  return (
    <div className="background no-footer">
      <div className="fixed-background" />
      <main>
        <div className="container">
          <div className="row h-100">
            <div className="col-12 col-md-10 mx-auto my-auto">
              <div className="card auth-card">
                <div className="position-relative image-side ">
                  <p className=" text-white h2">MAGIC IS IN THE DETAILS</p>
                  <p className="white mb-0">
                    Please use your credentials to login.
                    <br />If you are not a member, please
                    <a href="#" className="white"> register</a>.
                  </p>
                </div>
                <div className="form-side">
                  <a href="#">
                    <span className="logo-single" />
                  </a>
                  <h6 className="mb-4">Login</h6>
                  <form onSubmit={onSubmit}>
                    <div className="input-group mb-3">
                      <input ref={emailRef} type="email" className="form-control" placeholder="Email" required />
                      <div className="input-group-append">
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <input ref={passwordRef} type="password" className="form-control" placeholder="Password" required />
                      <div className="input-group-append">
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-12">
                        <button type="submit" className="btn btn-primary btn-block">Ingresar</button>
                      </div>
                      
                      <div className="col-12" style={styles}><br></br>
                        <a onClick={()=>recoveryPassword()} className="activatePointer">Recuperar contraseña</a>
                      </div>
                    </div>
                  </form>
                  {errors &&
                    <div className="alert">
                      {Object.keys(errors).map(key => (
                        <p className="alertMesagge" key={key}>{errors[key][0]}</p>
                      ))}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Login