import React from "react";

function NuevoItem() {
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Nueva Producción</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-xl-7 ">
            <div className="card mb-4 justify-content-center">
              <div className="lightbox">
                <img
                  alt="detail"
                  src="img/products/producto.jpeg"
                  className="responsive border-0 card-img-top mb-3"
                />
              </div>
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                >
                  <div className="form-group has-float-label">
                    <span>Producto</span>
                    <select
                      className="form-control select2-single"
                      name="jQueryTopLabelsState"
                      required
                      data-width="100%"
                    >
                      <option />
                      <option value="AZ">PARAMETROS</option>
                      <option value="CO">Colorado</option>
                      <option value="ID">Idaho</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NM">New Mexico</option>
                      <option value="ND">North Dakota</option>
                      <option value="UT">Utah</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <label>Cantidad</label>
                    <input
                      type="text"
                      className="form-control"
                      name="jQueryName"
                      required
                    />
                  </div>
                </form>
              </div>
              <a href="/produccion-control">
                <button
                  type="button"
                  className="btn btn-secondary btn-block mb-1"
                >
                  AGREGAR PRODUCCIÓN
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default NuevoItem;
