import React from "react";

function Inicio() {
  return (
    <>
      <div className="landing-page">
        <div className="main-container">
          <nav className="landing-page-nav">
            <div className="container d-flex align-items-center justify-content-between">
              <a className="navbar-logo pull-left scrollTo" href="/login">
                <span className="white" />
                <span className="dark" />
              </a>
              <a href="#" className="mobile-menu-button">
                <i className="simple-icon-menu" />
              </a>
            </div>
          </nav>
          <div className="content-container" id="home">
            <div className="section home">
              <div className="container">
                <div className="row home-row">
                  <div className="col-12 d-block d-md-none">
                    <a href="/login">
                      <img
                        alt="mobile hero"
                        className="mobile-hero"
                        src="img/landing-page/home-hero-mobile.png"
                      />
                    </a>
                  </div>
                  <div className="col-12 col-xl-4 col-lg-5 col-md-6">
                    <div className="home-text">
                      <div className="display-1">
                        EXCELENCIA
                        <br />
                        EN LOS DETALLES
                      </div>
                      <p className="white mb-5">
                        EVA es mucho más que una plataforma de administración
                        empresarial; es la culminación de un arduo trabajo,
                        dedicación y pasión por simplificar la gestión
                        empresarial. <br /> <br />
                        Con una interfaz minimalista y una atención obsesiva a
                        los detalles, EVA se convierte en el compañero perfecto
                        para aquellos que buscan alcanzar nuevos niveles de
                        eficiencia y productividad.
                      </p>
                      <a
                        className="btn btn-secondary btn-xl mr-2 mb-2"
                        href="/login"
                      >
                        ACCEDER <i className="simple-icon-arrow-right" />
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-xl-7 offset-xl-1 col-lg-7 col-md-6  d-none d-md-block">
                    <a target="_blank" href="/login">
                      <img alt="hero" src="img/landing-page/home-hero.png" />
                    </a>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-12 p-0">
                    <div className="owl-container">
                      <div className="owl-carousel home-carousel">
                        <div className="card">
                          <div className="card-body text-center">
                            <div>
                              <i className="iconsminds-business-mens large-icon" />
                              <h5 className="mb-3 font-weight-semibold">
                                Gestión de Clientes
                              </h5>
                            </div>
                            <div>
                              <p className="detail-text">
                                Administre y cultive relaciones sólidas con sus
                                clientes y prospectos mientras mantiene una base
                                de datos actualizada en todo momento. Con
                                nuestro intuitivo módulo de gestión de clientes,
                                mantenga un seguimiento detallado de cada
                                interacción y oportunidad, permitiendo un
                                servicio personalizado y una experiencia
                                excepcional para sus clientes.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body text-center">
                            <div>
                              <i className="iconsminds-box-close large-icon" />
                              <h5 className="mb-3 font-weight-semibold">
                                Manejo de Inventarios
                              </h5>
                            </div>
                            <div>
                              <p className="detail-text">
                                Optimice el flujo de sus productos y asegure un
                                control total sobre su inventario con nuestro
                                completo módulo de manejo de inventarios. Desde
                                la recepción hasta la distribución, mantenga una
                                visión clara y actualizada de todos sus activos
                                con EVA.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body text-center">
                            <div>
                              <i className="iconsminds-the-white-house large-icon" />
                              <h5 className="mb-3 font-weight-semibold">
                                Gestión de Proyectos
                              </h5>
                            </div>
                            <div>
                              <p className="detail-text">
                                Lleve sus proyectos al siguiente nivel con
                                nuestro robusto módulo de gestión de proyectos.
                                Coordine equipos, establezca hitos y mantenga un
                                seguimiento preciso del progreso en cada etapa
                                del camino. Con EVA, lograr sus objetivos nunca
                                ha sido tan fácil.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body text-center">
                            <div>
                              <i className="iconsminds-shop-4 large-icon" />
                              <h5 className="mb-3 font-weight-semibold">
                                Control de Productos, Pedidos y Ventas
                              </h5>
                            </div>
                            <div>
                              <p className="detail-text">
                                Mantenga el control total sobre sus productos,
                                pedidos y ventas con nuestro módulo integrado.
                                Desde la creación de productos hasta la gestión
                                de inventario y la administración de ventas, EVA
                                le proporciona las herramientas necesarias para
                                optimizar sus operaciones y aumentar su
                                rentabilidad..
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body text-center">
                            <div>
                              <i className="iconsminds-coins large-icon" />
                              <h5 className="mb-3 font-weight-semibold">
                                Seguimiento de Cartera
                              </h5>
                            </div>
                            <div>
                              <p className="detail-text">
                                Administre sus finanzas con confianza y
                                precisión utilizando nuestro módulo de
                                seguimiento de cartera. Desde los depositos
                                hasta el seguimiento de pagos pendientes,
                                mantenga una visión clara de su situación
                                financiera en todo momento con EVA.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body text-center">
                            <div>
                              <i className="iconsminds-checkout large-icon" />
                              <h5 className="mb-3 font-weight-semibold">
                                eCommerce
                              </h5>
                            </div>
                            <div>
                              <p className="detail-text">
                                Expanda su alcance y maximice sus ventas con
                                nuestro potente módulo de eCommerce. Cree una
                                tienda en línea atractiva, gestione catálogos de
                                productos y procese transacciones de manera
                                segura y eficiente, todo desde una plataforma
                                integrada con EVA.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body text-center">
                            <div>
                              <i className="iconsminds-handshake large-icon" />
                              <h5 className="mb-3 font-weight-semibold">
                                Gestión de Servicios
                              </h5>
                            </div>
                            <div>
                              <p className="detail-text">
                                Optimice la entrega de servicios y garantice la
                                satisfacción del cliente con nuestro completo
                                módulo de gestión de servicios. Desde la
                                programación y asignación de tareas hasta el
                                seguimiento del tiempo y la calidad del
                                servicio, mantenga un control total sobre cada
                                aspecto de sus operaciones de servicio.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body text-center">
                            <div>
                              <i className="iconsminds-idea large-icon" />
                              <h5 className="mb-3 font-weight-semibold">
                                Módulo Personalizado
                              </h5>
                            </div>
                            <div>
                              <p className="detail-text">
                                Construya una solución a medida que se adapte
                                perfectamente a las necesidades únicas de su
                                empresa con nuestro módulo personalizado.
                                Trabajando en estrecha colaboración con nuestro
                                equipo experto, diseñaremos y desarrollaremos
                                una solución personalizada que optimice sus
                                procesos y mejore su eficiencia operativa. Desde
                                la integración de sistemas hasta la creación de
                                flujos de trabajo específicos, aproveche al
                                máximo su plataforma EVA con un módulo diseñado
                                exclusivamente para usted.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <a
                    className="btn btn-circle btn-outline-semi-light hero-circle-button scrollTo"
                    href="#features"
                    id="homeCircleButton"
                  >
                    <i className="simple-icon-arrow-down" />
                  </a>
                </div> */}
              </div>
            </div>
            <div className="section">
              <div className="container" id="features">
                <div className="row">
                  <div className="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                    <h1>Belleza en su funcionalidad</h1>
                    <p>
                      Diseñada para ser intuitiva desde el primer momento, EVA
                      te invita a descubrir la magia de una administración
                      empresarial sin complicaciones.
                    </p>
                  </div>
                </div>
                <div className="row feature-row">
                  <div className="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                    <div className="d-flex">
                      <div className="feature-icon-container">
                        <div className="icon-background">
                          <i className="fas fa-fw fa-ban" />
                        </div>
                      </div>
                      <div className="feature-text-container">
                        <h2>Hablemos de diseño</h2>
                        <p>
                          En EVA, cada detalle es una expresión de nuestro
                          compromiso con la simplicidad, la belleza y la
                          funcionalidad. Desde el diseño de la interfaz hasta la
                          arquitectura de la plataforma, hemos trabajado
                          incansablemente para crear una experiencia que cautiva
                          tanto por su estética como por su eficacia. <br />
                          <br />
                          Nuestra obsesión por el diseño se refleja en cada
                          elemento de EVA. Cada línea, cada color, ha sido
                          meticulosamente seleccionado para proporcionar una
                          experiencia visualmente impactante y una usabilidad
                          sin igual. En EVA, la belleza y la funcionalidad van
                          de la mano. <br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 offset-lg-1 offset-md-0 position-relative">
                    <div className="background-item-1" />
                    <img
                      alt="feature"
                      className="feature-image-right feature-image-charts position-relative"
                      src="img/landing-page/features/plesant-design.png"
                    />
                  </div>
                </div>
                <div className="row feature-row">
                  <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                    <img
                      alt="feature"
                      className="feature-image-left feature-image-charts"
                      src="img/landing-page/features/extra-responsive.png"
                    />
                  </div>
                  <div className="col-12 col-md-6 offset-md-0 col-lg-5 offset-lg-1 d-flex align-items-center order-1 order-md-2">
                    <div className="d-flex">
                      <div className="feature-icon-container">
                        <div className="icon-background">
                          <i className="fas fa-fw fa-ban" />
                        </div>
                      </div>
                      <div className="feature-text-container">
                        <h2>Simple</h2>
                        <p>
                          En el corazón de EVA reside una filosofía: simplificar
                          sin comprometer la belleza ni la capacidad. Cada
                          función, cada herramienta, ha sido diseñada con el
                          propósito de hacer que la gestión empresarial sea
                          intuitiva y placentera. En EVA, la elegancia y la
                          utilidad convergen.
                          <br />
                          <br />
                          Hemos desafiado los límites del diseño y la tecnología
                          para crear una plataforma que inspira y facilita.
                          Fusionando arte y ciencia para crear una experiencia
                          única en su clase. Desde el diseño visual hasta la
                          navegación fluida, cada aspecto de nuestra plataforma
                          ha sido refinado para deleitar a nuestros usuarios. En
                          EVA, la belleza y la funcionalidad se fusionan en
                          armonía.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row feature-row">
                  <div className="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                    <div className="d-flex">
                      <div className="feature-icon-container">
                        <div className="icon-background">
                          <i className="fas fa-fw fa-ban" />
                        </div>
                      </div>
                      <div className="feature-text-container">
                        <h2>Seguridad</h2>
                        <p>
                          En EVA, la seguridad es nuestra prioridad número uno.
                          Desde el momento en que accedes a nuestra plataforma,
                          puedes estar seguro de que tus datos están protegidos
                          por las últimas medidas de seguridad cibernética. Con
                          EVA, tu tranquilidad está garantizada. <br />
                          <br />
                          Nuestro enfoque en la seguridad se extiende a cada
                          aspecto de la plataforma, desde la encriptación de
                          extremo a extremo hasta la autenticación multifactor.{" "}
                          <br />
                          <br />
                          En un mundo digitalmente interconectado, la seguridad
                          es primordial. En EVA, hemos implementado una
                          infraestructura de seguridad robusta y proactiva para
                          salvaguardar tus datos contra amenazas cibernéticas. .
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 offset-lg-1 offset-md-0 ">
                    <img
                      alt="feature"
                      className="feature-image-right feature-image-charts"
                      src="img/landing-page/features/superfine-charts.png"
                    />
                  </div>
                </div>
                <div className="row feature-row">
                  <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                    <img
                      alt="feature"
                      className="feature-image-left feature-image-charts"
                      src="img/landing-page/features/layouts-for-the-job.png"
                    />
                  </div>
                  <div className="col-12 col-md-6 offset-md-0 col-lg-5 offset-lg-1 d-flex align-items-center order-1 order-md-2">
                    <div className="d-flex">
                      <div className="feature-icon-container">
                        <div className="icon-background">
                          <i className="fas fa-fw fa-ban" />
                        </div>
                      </div>
                      <div className="feature-text-container">
                        <h2>Layouts for the Job</h2>
                        <p>
                          Layouts are the real thing, they need to be accurate
                          and right for the job. They should be functional for
                          both user and developer. <br />
                          <br />
                          We created lots of different layouts for different
                          jobs.
                          <br />
                          <br />
                          Listing pages with view mode changing capabilities,
                          shift select and select all functionality, application
                          layouts with an additional menu, authentication and
                          error layouts which has a different design than the
                          other pages were our main focus. We also created
                          details page with tabs that can hold many components.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row feature-row">
                  <div className="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                    <div className="d-flex">
                      <div className="feature-icon-container">
                        <div className="icon-background">
                          <i className="fas fa-fw fa-ban" />
                        </div>
                      </div>
                      <div className="feature-text-container">
                        <h2>Smart Menu</h2>
                        <p>
                          Instead of good old single panel menus with accordion
                          structure that looks over complicated, we created 2
                          panels and categorized pages accordingly.
                          <br />
                          <br />
                          The default menu auto hides sub panel when resolution
                          is under some breakpoint to open some space. You may
                          also hide menu completely or use only main panel open
                          only.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 offset-lg-1 offset-md-0 ">
                    <img
                      alt="feature"
                      className="feature-image-right feature-image-charts"
                      src="img/landing-page/features/smart-menu.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="section background">
              <div className="container" id="layouts">
                <div className="row">
                  <div className="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                    <h1>Structures &amp; Layouts</h1>
                    <p>
                      We did our best to create layouts for various needs that
                      developers might have and best experience for users.
                      <br />
                      They are clean and slick. They function well and look good
                      at the same time.
                    </p>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/menu-default.jpg"
                    />
                    <h4 className="text-center">Menu Default</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/menu-subhidden.jpg"
                    />
                    <h4 className="text-center">Menu Subhidden</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/menu-hidden.jpg"
                    />
                    <h4 className="text-center">Menu Hidden</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/image-list.jpg"
                    />
                    <h4 className="text-center">Image List</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/thumb-list.jpg"
                    />
                    <h4 className="text-center">Thumb List</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/data-list.jpg"
                    />
                    <h4 className="text-center">Data List</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/details.jpg"
                    />
                    <h4 className="text-center">Details</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5 semi-rounded">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/authentication.jpg"
                    />
                    <h4 className="text-center">Authentication</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/search-result.jpg"
                    />
                    <h4 className="text-center">Search Results</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/spa.jpg"
                    />
                    <h4 className="text-center">Single Page Application</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded"
                      src="img/landing-page/layouts/data-list-app-menu-hidden.jpg"
                    />
                    <h4 className="text-center">Data List App Menu Hidden</h4>
                  </div>
                  <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                    <img
                      className="img-fluid border-radius depth-2 mb-3 semi-rounded "
                      src="img/landing-page/layouts/tabs.jpg"
                    />
                    <h4 className="text-center">Tabs</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="section mb-0">
              <div className="container" id="components">
                <div className="row mb-5">
                  <div className="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                    <h1>Components</h1>
                    <p>
                      We used most popular and well managed open source
                      components with bootstrap components. Combined them into
                      even more useful ones. Themed them with same design
                      principles and created a design harmony between components
                      and layouts.
                      <br />
                      <br />
                      From carousels to charts, switches to list we tried to
                      provide components that we like to use on our development
                      processes.
                    </p>
                  </div>
                </div>
              </div>
              <img
                className="components-image mb-5 pb-5"
                src="img/landing-page/components.jpg"
              />
            </div>
            <div className="section background">
              <div className="container" id="apps">
                <div className="row">
                  <div className="col-12 offset-0 col-lg-8 offset-lg-2 text-center mb-4">
                    <h1>Applications</h1>
                    <p className="section-text">
                      With the help of components and layouts, we created four
                      different applications. They are a good way to get you
                      started if you want to build something similar.
                      <br />
                    </p>
                  </div>
                </div>
                <div className="row screenshots">
                  <div className="col-12 text-center mb-4">
                    <ul
                      className="nav nav-tabs justify-content-center mb-4"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#library"
                          role="tab"
                        >
                          <p>Library</p>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#survey"
                          role="tab"
                        >
                          <p>Survey</p>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#chat"
                          role="tab"
                        >
                          <p>Chat</p>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#todo"
                          role="tab"
                        >
                          <p>Todo</p>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active justify-content-center"
                        id="library"
                        role="tabpanel"
                      >
                        <a target="_blank" href="/login">
                          <img
                            alt="app image"
                            className="app-image"
                            src="img/landing-page/applications/library.jpg"
                          />
                        </a>
                      </div>
                      <div
                        className="tab-pane fade justify-content-center"
                        id="survey"
                        role="tabpanel"
                      >
                        <a target="_blank" href="/login">
                          <img
                            alt="app image"
                            className="app-image"
                            src="img/landing-page/applications/survey.jpg"
                          />
                        </a>
                      </div>
                      <div
                        className="tab-pane fade justify-content-center"
                        id="chat"
                        role="tabpanel"
                      >
                        <a target="_blank" href="/login">
                          <img
                            alt="app image"
                            className="app-image"
                            src="img/landing-page/applications/chat.jpg"
                          />
                        </a>
                      </div>
                      <div
                        className="tab-pane fade justify-content-center"
                        id="todo"
                        role="tabpanel"
                      >
                        <a target="_blank" href="/login">
                          <img
                            alt="app image"
                            className="app-image"
                            src="img/landing-page/applications/todo.jpg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section mb-0">
              <div className="container" id="themes">
                <div className="row mb-5">
                  <div className="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                    <h1>Themes</h1>
                    <p>
                      We carefully choosed colors and created 10 different
                      themes with dark and light versions. You may also create
                      your own themes easily since all the theme related styling
                      is managed by Sass variables.
                    </p>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Navy Blue</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left bluenavy-light-1" />
                      <div className="color-center bluenavy-light-2" />
                      <div className="color-right bluenavy-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left bluenavy-dark-1" />
                      <div className="color-center bluenavy-dark-2" />
                      <div className="color-right bluenavy-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Olympic Blue</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left blueolympic-light-1" />
                      <div className="color-center blueolympic-light-2" />
                      <div className="color-right blueolympic-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left blueolympic-dark-1" />
                      <div className="color-center blueolympic-dark-2" />
                      <div className="color-right blueolympic-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Yale Blue</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left blueyale-light-1" />
                      <div className="color-center blueyale-light-2" />
                      <div className="color-right blueyale-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left blueyale-dark-1" />
                      <div className="color-center blueyale-dark-2" />
                      <div className="color-right blueyale-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Moss Green</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left greenmoss-light-1" />
                      <div className="color-center greenmoss-light-2" />
                      <div className="color-right greenmoss-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left greenmoss-dark-1" />
                      <div className="color-center greenmoss-dark-2" />
                      <div className="color-right greenmoss-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Lime Green</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left greenlime-light-1" />
                      <div className="color-center greenlime-light-2" />
                      <div className="color-right greenlime-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left greenlime-dark-1" />
                      <div className="color-center greenlime-dark-2" />
                      <div className="color-right greenlime-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Carrot Orange</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left carrotorange-light-1" />
                      <div className="color-center carrotorange-light-2" />
                      <div className="color-right carrotorange-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left carrotorange-dark-1" />
                      <div className="color-center carrotorange-dark-2" />
                      <div className="color-right carrotorange-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Ruby Red</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left rubyred-light-1" />
                      <div className="color-center rubyred-light-2" />
                      <div className="color-right rubyred-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left rubyred-dark-1" />
                      <div className="color-center rubyred-dark-2" />
                      <div className="color-right rubyred-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Monster Purple</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left monsterpurple-light-1" />
                      <div className="color-center monsterpurple-light-2" />
                      <div className="color-right monsterpurple-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left monsterpurple-dark-1" />
                      <div className="color-center monsterpurple-dark-2" />
                      <div className="color-right monsterpurple-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Steel Grey</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left steelgrey-light-1" />
                      <div className="color-center steelgrey-light-2" />
                      <div className="color-right steelgrey-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left steelgrey-dark-1" />
                      <div className="color-center steelgrey-dark-2" />
                      <div className="color-right steelgrey-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="text-center">Granola Yellow</h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left granolayellow-light-1" />
                      <div className="color-center granolayellow-light-2" />
                      <div className="color-right granolayellow-light-3" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="depth-2 color-container">
                      <div className="color-left granolayellow-dark-1" />
                      <div className="color-center granolayellow-dark-2" />
                      <div className="color-right granolayellow-dark-3" />
                    </div>
                  </div>
                  <div className="col-12 mb-5"></div>
                  <div className="col-12 mb-5"></div>
                </div>
              </div>
            </div>
            <div className="section background background-no-bottom mb-0 pb-0">
              <div className="container">
                <div className="row">
                  <div className="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                    <h1>Enjoying so Far?</h1>
                    <p>
                      Purchase Dore to get a fresh start with your new project.
                    </p>
                  </div>
                  <div className="col-12 offset-0 col-lg-6 offset-lg-3 newsletter-input-container">
                    <div className="text-center mb-3">
                      <a
                        className="btn btn-secondary btn-xl"
                        target="_top"
                        href="https://1.envato.market/5kAb"
                      >
                        BUY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section footer mb-0">
              <div className="container">
                <div className="row footer-row">
                  <div className="col-12 text-right">
                    <a
                      className="btn btn-circle btn-outline-semi-light footer-circle-button scrollTo"
                      href="#home"
                      id="footerCircleButton"
                    >
                      <i className="simple-icon-arrow-up" />
                    </a>
                  </div>
                  <div className="col-12 text-center footer-content">
                    <a href="#home" className="scrollTo">
                      <img
                        className="footer-logo"
                        alt="footer logo"
                        src="logos/white-full.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="separator mt-5" />
              <div className="container copyright pt-5 pb-5">
                <div className="row">
                  <div className="col-12" />
                  <div className="col-12 text-center">
                    <p className="mb-0">2024 © Desarrollo de Software RUBIANO</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Inicio;
