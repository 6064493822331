import React, { useState, useEffect } from "react";
import axiosClient from "../../axios-client";

function TodosPlanos(props) {
  const [productos, setProductos] = useState([]);

  let permisos = props.permisosAsignados;
  if(!permisos.includes('planos_view') && permisos!='-1'){
    window.location = '/home';
  }

  const dataProductos = () =>{
    let payload;
    axiosClient.get('/obtener-productos', payload)
    .then(({ data }) => {
      setProductos(data);
    }).catch(function (error) {
        console.log(error);
    });
  }

  const cargarPlano = (id) => {
    window.location = "/planos-cargar/"+id;
  };

  useEffect(() => {
    dataProductos();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Todos los Planos</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <table className="data-table cont_tabla responsive">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Código</th>
                      <th>Producto</th>
                      <th>Fecha de Plano</th>
                      <th>Plano</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                  { productos.map((registro, i) => (
                    <tr key={i}>
                      <td>{registro.id}</td>
                      <td>{registro.codigo}</td>
                      <td>{registro.titulo}</td>
                      <td>{registro.fecha_plano_formato}</td>
                      <td>
                        {registro.fecha_plano ? <a href={registro.plano} target="_blank"><i className="simple-icon-map align-middle p-2 heading-icon" /></a> : ''}
                      </td>
                      <td>
                      {permisos.includes('planos_add') || permisos=='-1' ?
                        <a onClick={() => cargarPlano(registro.id)} key={i}>
                          <button
                            type="button"
                            className="btn btn-primary btn-xs mb-1"
                          >
                            Cargar Plano
                          </button>
                        </a>
                        : ''}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default TodosPlanos;
