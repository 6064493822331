import React, { useEffect, useState, createRef } from 'react'
import { useParams } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Swal from 'sweetalert2';

function HacerDespachos(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('despachos_add') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [pedidos, setPedidos] = useState([]);
  const [notas, setNotas] = useState([]);
  const [nombreCliente, setCliente] = useState([]);
  const [razonSocial, setRazonSocial] = useState([]);
  const [negociacionCliente, setNegociacion] = useState([]);
  const [negociacionPedido, setNegociacionPedido] = useState([]);
  const [despachos, setDespachos] = useState([]);

  const notaRef = createRef();
  const id = useParams();

  const consultarPedidos = () => {
    let payload = {
      id_pedido: id.id,
      despacho: 1
    }
    axiosClient.post('/consultar-pedido-detalle', payload)
    .then(({data})=> {
      setPedidos(data.items)
      setCliente(data.cliente[0].nombre);
      setRazonSocial(data.cliente[0].razon_social);
      setNegociacion(data.cliente[0].negociacion);
      setNegociacionPedido(data.cliente[0].negociacionPedido);
    })
  }

  const verNotas = (id_registro) => {
    let payload = {
      id_registro
    }
    localStorage.setItem('id_pedido', id_registro);
    axiosClient.post('/notas-pedido-item', payload)
    .then(({data})=> {
      setNotas(data)
    })
  }

  const onSubmitNota = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_pedido_item: localStorage.getItem("id_pedido"),
      nota: notaRef.current.value
    }
    axiosClient.post("/agregar-nota-pedido-item", payload)
      .then(({ data_ }) => {
        verNotas(localStorage.getItem("id_pedido"))
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  const crearDespacho = () => {
    let data = pedidos;
    for(let i in data){
      data[i]['despachar'] = $("#"+data[i].id).val();
      if($(".archivar_"+data[i].id).prop('checked')){
        data[i]['archivar'] = 'si';
      }else{
        data[i]['archivar'] = 'no';
      }
    }
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_pedidos: id.id,
      tipo: '2',
      productos: data
    }
    axiosClient.post('/crear-despacho', payload)
    .then(({data_}) => {
      for(let j in data){
        if(data[j].archivar=='si'){
          actualizarEstado(data[j].id)
        }
      }
      window.location = '/despachos-ver';
    })
  }

  const actualizarEstado = (id_registro) => {
    let payload = {
      id_registro,
      estado: '4',
      tabla: 'pedidos_item',
      id_pedido: id.id
    }
    axiosClient.post("/enviar-estado-tabla", payload);
  };

  const consultarDespachos = () => {
    let payload = {
      id_pedido: id.id
    }
    axiosClient.post('/obtener-despachos', payload)
    .then(({data})=> {
      setDespachos(data);
    })
  }

  useEffect(() => {
    consultarPedidos();
    consultarDespachos();
  },[])

  return (
    <main>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>Despachar</h1>
              <p className="list-item-heading">
                <strong>Cliente:</strong> {razonSocial ? razonSocial+" - " : ''}{nombreCliente}<br></br>
                <strong>Negociación cliente:</strong> {negociacionCliente}<br></br>
                <strong>Negociación pedido:</strong> {negociacionPedido}
              </p>
              
            </div>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <table id="datatableRow" className="data-table responsive nowrap cont_tabla" data-order="[[ 1, &quot;desc&quot; ]]">
              <thead>
                <tr>
                  <th>ID Item</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Stock</th>
                  <th>Despachar</th>
                  <th>Notas</th>
                  <th><center>Archivar</center></th>
                </tr>
              </thead>
              <tbody>
                {pedidos.map((registro, i) => (
                <tr key={i}>
                  <td>
                    <p className="list-item-heading">{registro.id}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.codigo}
                      <br /><span className="text-muted">{registro.titulo}</span></p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.cantidad}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.stock}</p>
                  </td>
                  <td>
                    <input type="text" className="form-control" id={registro.id} />
                  </td>
                  <td>
                    <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" onClick={() => verNotas(registro.id)}>
                      Notas
                    </button>
                  </td>
                  <td>
                    <center>
                      <input type="checkbox" className={"archivar_"+registro.id}/>
                    </center>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
            <a onClick={() => crearDespacho()}><button type="button" className="btn btn-primary btn-block mb-1">Crear Documento de DESPACHO</button></a>
          </div>
        </div>
      </div>

      <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>Despachos realizados</h1>
            </div>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <table className="data-table cont_tabla responsive">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Cliente</th>
                      <th>Despacho</th>
                      <th>Detalle</th>
                      <th>Notas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {despachos.map((registro, i) => (
                    <tr key={i}>
                      <td>{registro.fecha}</td>
                      <td>{registro.razon_social ? registro.razon_social+' - ' : ''}{registro.nombre}</td>
                      <td><strong>{registro.id}</strong></td>
                      <td><a href={'/despachos-despachados/'+registro.id} target='_blank'><button type="button" className="btn btn-primary mb-1">Ver Despacho</button></a></td>
                      <td>
                        <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" onClick={() => verNotas(registro.id)}>
                          Ver Notas
                        </button>
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
          </div>
        </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Notas:
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <h5 className="card-title" />
                <div>
                {notas.map((value, key) => (
                  <div key={key} className="d-flex flex-row mb-3 border-bottom justify-content-between">
                    <a>
                        <img
                           src={value.users.image}
                          alt={value.users.name}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                      </a>
                      <div className="pl-3 flex-grow-1">
                        <p className="list-item-heading mb-0 ">
                          {value.users.name} &nbsp;
                          <span className="text-muted mb-0 text-small">
                            {value.fecha}
                            <i className="simple-icon-clock mr-1" />
                            {value.hora}
                          </span>{" "}
                        </p>
                        <p className="mt-3">
                          <i className="simple-icon-bubble mr-1 heading-icon" />
                          {value.nota}
                        </p>
                      </div>
                  </div>
                  ))}
                  <form onSubmit={handleSubmit(onSubmitNota)}>
                    <div className="comment-contaiener">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agregar Nota"
                          ref={notaRef}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="submit">
                            <span className="d-inline-block">Guardar</span>
                            <i className="simple-icon-arrow-right ml-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

    </main>
  )
}

export default HacerDespachos;