import React from "react";
import { useState, useEffect } from "react";
import axiosClient from "../../axios-client";
import { useParams } from "react-router-dom";

function CarteraDetalleCliente(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('cartera_view') && permisos!='-1'){
    window.location = '/home';
  }
  
  const [nombreCliente, setNombreCliente] = useState([]);
  const [razonSocial, setRazonSocial] = useState([]);
  const [pagos, setPagos] = useState([]);
  const id = useParams();

  const consultarCliente = () => {
    let payload = {
      id: id.id
    }
    axiosClient.post('/obtener-cliente', payload)
    .then(({data}) => {
      setPagos(data)
      setNombreCliente(data[0].nombre)
      setRazonSocial(data[0].razon_social)
    })
  }

  const consultarDetalleFactura = () => {
    let payload = {
      id_cliente: id.id
    }
    axiosClient.post('/detalle-documento-cliente', payload)
    .then(({data}) => {
      setPagos(data)
      console.log(data)
    })
  }

  const verEstadoFactura = (id) => {
    window.location = '/cartera-detalle-factura/'+id;
  }

  useEffect(() => {
    consultarCliente()
    consultarDetalleFactura()
    
  }, [])

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Pagos</h1>
            <p className="list-item-heading">{razonSocial ? razonSocial+' - ' : ''} {nombreCliente}</p>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <table
              id="datatableRos"
              className="data-table responsive nowrap cont_tabla_export"
              data-order='[[ 0, "desc" ]]'
            >
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Pago</th>
                  <th>Valor</th>
                  <th>Documento</th>
                </tr>
              </thead>
              <tbody>
                {pagos.map((registro,i)=>(
                  <tr key={i}>
                    <td>
                      <p className="list-item-heading">{registro.fecha_pago}</p>
                    </td>
                    <td>
                      <p className="list-item-heading">
                        {registro.tipo_pago}
                      </p>
                    </td>
                    <td>
                      <p className="list-item-heading">{registro.valor_formato}</p>
                    </td>
                    <td>
                      <p className="text-muted activatePointer" onClick={() => verEstadoFactura(registro.id_documentos)}>{registro.numero_documento}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CarteraDetalleCliente;
