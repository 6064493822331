import React, { createRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

function CrearDocumento(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('documentacion_add') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [notas, setNotas] = useState([]);
  const notaRef = createRef();

  const [despacho, setDespacho] = useState([]);
  const [dataDespacho, setDetalleDespacho] = useState([]);
  const [total, setTotal] = useState([]);
  const [totalFormato, setTotalFormato] = useState([]);
  const [tipoSeleccionado, setTipo] = useState();
  const [idCliente, setIDCliente] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [negociacionCliente, setNegociacionCliente] = useState([]);
  const [negociacionPedido, setNegociacionPedido] = useState([]);

  const [numeroFactura, setNumeroFactura] = useState([]);
  const [numeroRemision, setNumeroRemision] = useState([]);

  const id = useParams();

  const totalRef = createRef();
  const plazoRef = createRef();

  const elegirTipo = (tipo) => {
    setTipo(tipo)
  }

  const consultarConsecutivo = () => {
    axiosClient.get('/consultar-consecutivo')
    .then(({data})=>{
      setNumeroFactura(data[0].factura)
      setNumeroRemision(data[0].remision)
    })
  }

  const consultarDetalleDespacho = () => {
    let payload = {
      id_despacho: id.id
    }
    axiosClient.post('/detalle-despacho', payload)
    .then(({data})=>{
      setDetalleDespacho(data[0].fecha);
      setTotal(data[0].total);
      setTotalFormato(data[0].total_formato);
      setIDCliente(data[0].idCliente);
      setCliente(data[0].razon_social+" - "+data[0].nombre);
      setNegociacionCliente(data[0].negociacionCliente)
      setNegociacionPedido(data[0].negociacionPedido)
    })
  }

  const consultarDespacho = () => {
    let payload = {
      id_despacho: id.id
    }
    axiosClient.post('/consultar-despacho-detalle', payload)
    .then(({data})=>{
      setDespacho(data)
    })
  }

  const crearDocumento = () => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      tipo: tipoSeleccionado,
      total: totalRef.current.value,
      id_cliente: idCliente,
      id_despacho: id.id,
      factura: numeroFactura,
      remision: numeroRemision,
      plazo: plazoRef.current.value
    }
    if(payload.total && payload.tipo && payload.plazo){
      axiosClient.post('/crear-documento', payload)
      .then(({data})=> {
        window.location = '/documentar-listado';
      })
    }else{
      Swal.fire({
        icon: 'info',
        text: 'Seleccione tipo de documento, ingrese un valor y establezca un plazo'
      })
    }
  }

  const verNotas = (id_registro) => {
    let payload = {
      id_registro
    }
    localStorage.setItem('id_pedido', id_registro);
    axiosClient.post('/notas-pedido-item', payload)
    .then(({data})=> {
      setNotas(data)
    })
  }

  const onSubmitNota = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_pedido_item: localStorage.getItem("id_pedido"),
      nota: notaRef.current.value
    }
    axiosClient.post("/agregar-nota-pedido-item", payload)
      .then(({ data_ }) => {
        verNotas(localStorage.getItem("id_pedido"))
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  useEffect(() => {
    consultarDespacho();
    consultarDetalleDespacho();
    consultarConsecutivo();
  }, [])

  return (
    <main>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>Despacho # {id.id} del {dataDespacho}</h1>
            </div>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <table id="datatableRow" className="data-table responsive nowrap cont_tabla_export" data-order="[[ 1, &quot;desc&quot; ]]">
              <thead>
                <tr>
                  <th>Pedido</th>
                  <th>Producto</th>
                  <th>Despachados</th>
                  <th>Valor Uni</th>
                  <th>Valor Total</th>
                  <th>Notas</th>
                </tr>
              </thead>
              <tbody>
                {despacho.map((registro, i) => (
                <tr key={i}>
                  <td>
                    <p className="list-item-heading">{registro.id_despachos}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.codigo}
                      <br /><span className="text-muted">{registro.titulo}</span></p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.despachado}</p>
                  </td>
                  <td>
                    <strong>{registro.precio_formato}</strong>
                  </td>
                  <td>
                    <strong>{registro.precio_total_formato}</strong>
                  </td>
                  <td><button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" onClick={() => verNotas(registro.id)}>
                    Notas
                  </button>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>

            <div className="card mb-4">
              <div className="card-body">
                <h5 className="mb-4">{cliente}</h5>
                <p>
                  <strong>Negociación del Cliente:</strong> {negociacionCliente} <br></br>
                  <strong>Negociación del Pedido:</strong> {negociacionPedido}
                </p>
                <form className="form-inline">
                  <div className="input-group mb-2 mr-sm-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">{totalFormato}</div>
                    </div>
                    <input type="number" className="form-control" id="inlineFormInputGroupUsername2" placeholder={total} ref={totalRef}/>
                  </div>&nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <div className="custom-control custom-radio">
                      <input type="radio" id="jQueryCustomRadio1" name="jQueryCustomRadio" className="custom-control-input" required onClick={() => elegirTipo('1')}/>
                      <label className="custom-control-label" for="jQueryCustomRadio1">Factura #{numeroFactura}</label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input type="radio" id="jQueryCustomRadio2" name="jQueryCustomRadio" className="custom-control-input" required onClick={() => elegirTipo('2')}/>
                      <label className="custom-control-label" for="jQueryCustomRadio2">Remisión #{numeroRemision}</label>
                    </div>
                  </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div className="input-group mb-2 mr-sm-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">Plazo en días</div>
                    </div>
                    <input type="number" className="form-control" id="inlineFormInputGroupUsername2" placeholder="30" ref={plazoRef}/>
                  </div>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button type="button" onClick={() => crearDocumento()} className="btn btn-sm btn-primary mb-2">Crear Documento </button>
                </form>
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Notas:
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <h5 className="card-title" />
                <div>
                {notas.map((value, key) => (
                  <div key={key} className="d-flex flex-row mb-3 border-bottom justify-content-between">
                    <a>
                        <img
                           src={value.users.image}
                          alt={value.users.name}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                      </a>
                      <div className="pl-3 flex-grow-1">
                        <p className="list-item-heading mb-0 ">
                          {value.users.name} &nbsp;
                          <span className="text-muted mb-0 text-small">
                            {value.fecha}
                            <i className="simple-icon-clock mr-1" />
                            {value.hora}
                          </span>{" "}
                        </p>
                        <p className="mt-3">
                          <i className="simple-icon-bubble mr-1 heading-icon" />
                          {value.nota}
                        </p>
                      </div>
                  </div>
                  ))}
                  <form onSubmit={handleSubmit(onSubmitNota)}>
                    <div className="comment-contaiener">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agregar Nota"
                          ref={notaRef}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="submit">
                            <span className="d-inline-block">Guardar</span>
                            <i className="simple-icon-arrow-right ml-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      
    </main>
  )
}

export default CrearDocumento;