import React, { useState, useEffect } from "react";
import axiosClient from "../axios-client.js";

function Header() {
  const currentTheme = localStorage.getItem('dore-theme-color');
  const [checked, setChecked] = useState(currentTheme === "dore.dark.bluenavy.min.css");
  var theme = "dore.light.bluenavy.min.css";

  if (typeof Storage !== "undefined") {
    if (localStorage.getItem("dore-theme-color")) {
      theme = localStorage.getItem("dore-theme-color");
    } else {
      localStorage.setItem("dore-theme-color", theme);
    }
  }

  function changeTheme(e) {
    if (e.target.checked) {
      localStorage.setItem("dore-theme-color", "dore.dark.bluenavy.min.css");
      setChecked(true);
    } else {
      localStorage.setItem("dore-theme-color", "dore.light.bluenavy.min.css");
      setChecked(false);
    }
    window.location.reload();
  }

  const [user, setUser] = useState('Nombre de Usuario');
  const [imageUser, setImageUser] = useState('user-thumbnail.png');

  const irHome = async (ev) => {
    ev.preventDefault();
    window.location.href = "/home";
  }

  const irMiPerfil = async (ev) => {
    ev.preventDefault();
    window.location.href = "/configuracion-mi-perfil";
  }

  useEffect(() => {
    setUser(localStorage.getItem('NAME_USER'));
    setImageUser(localStorage.getItem('IMAGE_USER'));
  }, []);

  return (
    <nav className="navbar fixed-top">
      <div className="d-flex align-items-center navbar-left">
        <a href="#" className="menu-button d-none d-md-block">
          <svg
            className="main"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 9 17"
          >
            <rect x="0.48" y="0.5" width={7} height={1} />
            <rect x="0.48" y="7.5" width={7} height={1} />
            <rect x="0.48" y="15.5" width={7} height={1} />
          </svg>
          <svg
            className="sub"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 17"
          >
            <rect x="1.56" y="0.5" width={16} height={1} />
            <rect x="1.56" y="7.5" width={16} height={1} />
            <rect x="1.56" y="15.5" width={16} height={1} />
          </svg>
        </a>
        <a
          href="#"
          className="menu-button-mobile d-xs-block d-sm-block d-md-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width={25} height={1} />
            <rect x="0.5" y="7.5" width={25} height={1} />
            <rect x="0.5" y="15.5" width={25} height={1} />
          </svg>
        </a>
        <a className="btn btn-sm btn-outline-primary ml-3 d-none d-md-inline-block"
          href="#">INDUMAN
        </a>
      </div>
      <a className="navbar-logo" href="/home">
        <span className="logo d-none d-xs-block" />
        <span className="logo-mobile d-block d-xs-none" />
      </a>
      <div className="navbar-right">
        <div className="header-icons d-inline-block align-middle">
          <div className="d-none d-md-inline-block align-text-bottom mr-3">
            <div
              className="custom-switch custom-switch-primary-inverse custom-switch-small pl-1"
              data-toggle="tooltip"
              data-placement="left"
              title="Modo Oscuro"
            >
              <input
                className="custom-switch-input"
                id="switchDark"
                type="checkbox"
                checked={checked}
                onChange={changeTheme}
              />
              <label className="custom-switch-btn" htmlFor="switchDark" />
            </div>
          </div>
          <button
            className="header-icon btn btn-empty d-none d-sm-inline-block"
            type="button"
            id="fullScreenButton"
          >
            <i className="simple-icon-size-fullscreen" />
            <i className="simple-icon-size-actual" />
          </button>
        </div>
        <div className="user d-inline-block">
          <button
            className="btn btn-empty p-0"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="name">{user}</span>
            <span>
              <img alt={user} src={imageUser} />
            </span>
          </button>
          <div className="dropdown-menu dropdown-menu-right mt-3">
          <a className="dropdown-item" onClick={irHome}>
              Dashboard
            </a>
            <a className="dropdown-item" onClick={irMiPerfil}>
              Mi Perfil
            </a>
            <a className="dropdown-item" href="/logout">
              Cerrar sesión
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
