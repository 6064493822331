import React, { useState, useEffect, createRef } from "react";
import { useForm } from "react-hook-form";
import axiosClient from "../../axios-client";

function VerInventario(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('inventario_view') && permisos!='-1'){
    window.location = '/home';
  }

  const [productos, setProductos] = useState([]);
  const [historico, setHistoricoMovimiento] = useState([]);
  const [codigo, setCodigo] = useState([]);

  const getHistoricoMovimiento = (id_producto) => {
    let payload = {
      id_producto
    }
    axiosClient.post('/historico-movimiento-producto', payload)
      .then(({ data }) => {
        setHistoricoMovimiento(data.data);
        setCodigo(data.codigo)
      }).catch(function (error) {
          console.log(error);
      });
  }

  const dataProductos = () =>{
    axiosClient.get('/obtener-productos')
    .then(({ data }) => {
      setProductos(data);
    }).catch(function (error) {
        console.log(error);
    });
  }

  useEffect(() => {
    dataProductos();
  }, []);
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Inventario</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <table className="data-table cont_tabla responsive">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Codigo</th>
                      <th>Producto</th>
                      <th>Cantidad Actual</th>
                      <th>Ubicación</th>
                      <th>Movimientos</th>
                    </tr>
                  </thead>
                  <tbody>
                  { productos.map((registro, i) => (
                    <tr key={i}>
                      <td>{registro.id}</td>
                      <td>{registro.codigo}</td>
                      <td>{registro.titulo}</td>
                      <td>{registro.cantidad}</td>
                      <td>{registro.ubicacion}</td>
                      <td>
                        <button type="button" className="btn btn-primary btn-xs mb-1" data-toggle="modal" data-target="#exampleModal" onClick={() => getHistoricoMovimiento(registro.id)}>
                          Ver Movimientos
                        </button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Histórico de Inventario
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Código: {codigo}</th>
                    <th>Entradas/Salidas</th>
                  </tr>
                </thead>
                <tbody>
                { historico.map((registro, i) => (
                    <tr key={i}>
                      <td>{registro.fecha}</td>
                      <td>{registro.total_formato}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default VerInventario;
