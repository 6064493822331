import React from 'react'

function Informacion(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('paginaweb_view') && permisos!='-1'){
    window.location = '/home';
  }
  const onSubmit = (ev) => {
    ev.preventDefault();
    window.location = "/web-articulos-publicados";
  };
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="mb-2">
              <h1>Información Página Web</h1>
            </div>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-xl-7 col-left">
            <div className="card mb-4">
              <div className="lightbox">
                  <img
                    alt="detail"
                    src="./img/pngegg.png"
                    className="responsive border-0 card-img-top mb-3"
                  />
              </div>
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={onSubmit}
                >
                  <div className="form-group has-float-label">
                    <label>Dirección</label>
                    <input
                      type="text"
                      className="form-control"
                      name="jQueryName"
                      required
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Teléfono</label>
                    <input
                      type="text"
                      className="form-control"
                      name="jQueryName"
                      required
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Banner Superior</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      name="jQueryDetail"
                      required
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group has-float-label">
                  <label>Banner Inferior</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      name="jQueryDetail"
                      required
                      defaultValue={""}
                    />
                  </div>
                  <button className="btn btn-primary" type="submit">
                    Actualizar información
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target=".bd-example-modal-sm"
                  >
                    <i className="glyph-icon simple-icon-trash" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade bd-example-modal-sm"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                ¿Realmente desea eliminar el registro?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <button className="btn btn-danger" type="button" data-dismiss="modal" aria-label="Close">
                <span className="d-inline-block">ELIMINAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Informacion