import { useState, useEffect, createRef } from "react";
import axiosClient from "../axios-client.js";
import EnviarComentario from "../componentes/EnviarComentario.jsx";
import { useForm } from "react-hook-form";

function Home() {
  const [datos, setDatos] = useState([]);
  const [frase, setFrase] = useState([]);
  const [pendientes, setPendientes] = useState([]);
  const [publicaciones, setPublicaciones] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sinActividad, setSinActividad] = useState(false);
  const descripcionRef = createRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (payload) => {
    setSinActividad(true);
    axiosClient
      .post(
        `/crear-publicacion`,
        {
          mensaje: payload.mensaje,
          file: selectedFile,
          id_user: localStorage.getItem("ID_USER")
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        consultarPublicaciones();
        setSinActividad(false);
      });
  };

  const irTodasPublicaciones = () => {
    window.location.href = "/publicaciones-todas";
  };

  const actualizarPendiente = (idPendiente) => {
    let payload = {
      id: idPendiente,
    };
    axiosClient.post("/actualizar-pendiente", payload).then(({ data }) => {
      consultarPendientes();
    });
  };

  const sumarLikePublicacion = (idPublicacion) => {
    let payload = {
      id: idPublicacion,
    };
    axiosClient.post("/sumar-like-publicacion", payload).then(({ data }) => {
      consultarPublicaciones();
    });
  };

  const sumarLikeComentario = (idComentario) => {
    let payload = {
      id: idComentario,
    };
    axiosClient.post("/sumar-like-comentario", payload).then(({ data }) => {
      consultarPublicaciones();
    });
  };

  const guardarPendiente = () => {
    let payload = {
      descripcion: descripcionRef.current.value,
      id_user: localStorage.getItem('ID_USER')
    };
    axiosClient.post("/agregar-pendiente", payload).then(({ data }) => {
      consultarPendientes();
      descripcionRef.current.value = "";
    });
  };

  const consultarPendientes = () => {
    let payload = {
      id_user: localStorage.getItem("ID_USER")
    };
    axiosClient.post("/consultar-pendientes", payload).then(({ data }) => {
      setPendientes(data.pendientes);
    });
  };

  const consultarPublicaciones = () => {
    axiosClient
      .get("/obtener-publicaciones")
      .then(({ data }) => {
        setPublicaciones(data);
      })
      .catch((err) => {
        const response = err.response;
      });
  };

  useEffect(() => {
    axiosClient
      .get("/users")
      .then(({ data }) => {
        let arrayData = Object.keys(data).map((key) => [key, data[key]]);
        setDatos(arrayData);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
    axiosClient
      .get("/frases")
      .then(({ data }) => {
        setFrase(data[0].descripcion);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
    consultarPendientes();
    consultarPublicaciones();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Bienvenidos</h5>
                <div className="scroll dashboard-list-with-user">
                  {datos.map((value, key) => (
                    <div
                      key={key}
                      className="d-flex flex-row mb-3 pb-3 border-bottom"
                    >
                      <img
                        src={value[1].image}
                        alt={value[1].name}
                        className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                      />
                      <div className="pl-3">
                        <p className="font-weight-medium mb-0 ">
                          {value[1].name}
                        </p>
                        <p className="text-muted mb-0 text-small">
                          {value[1].last_entry_at}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-5">
            <div className="card dashboard-sq-banner justify-content-end">
              <div className="card-body justify-content-end d-flex flex-column">
                <span className="badge badge-pill badge-theme-3 align-self-start mb-3">
                  EVA
                </span>
                <p className="lead text-white">{frase}</p>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mb-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Pendientes</h5>
                <div className="scroll dashboard-logs">
                  <table className="table table-sm table-borderless">
                    <tbody>
                      {pendientes.map(
                        (value, key) =>
                          value.realizada=='0' && (
                            <tr key={key}>
                              <td>
                                <span className="log-indicator border-theme-1 align-middle" />
                              </td>
                              <td>
                                <span className="font-weight-medium">
                                  {value.descripcion}
                                </span>
                              </td>
                              <td className="text-right">
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="mb-0 text-primary w-20 w-xs-100 text-right"
                                  onClick={() => actualizarPendiente(value.id)}
                                >
                                  <i className="simple-icon-check" />
                                </p>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                  <table className="table table-sm table-borderless">
                    <tbody>
                      {pendientes.map(
                        (value2, key2) =>
                          value2.realizada == '1' && (
                            <tr key={key2}>
                              <td>
                                <span className="log-indicator border-light align-middle" />
                              </td>
                              <td>
                                <span className="text-muted">
                                  {value2.descripcion}
                                </span>
                              </td>
                              <td className="text-right">
                                <span className="text-muted">
                                  {value2.fecha_realizada}
                                </span>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Agregar pendiente"
                    ref={descripcionRef}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={guardarPendiente}
                    >
                      <span className="d-inline-block">Guardar</span>
                      <i className="simple-icon-arrow-right ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-xl-5">
            <div className="icon-cards-row">
              <div className="glide dashboard-numbers">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    <li className="glide__slide">
                      <a href="/pedidos-nuevo" className="card">
                        <div className="card-body text-center">
                          <i className="iconsminds-box-close" />
                          <p className="card-text mb-0">Nuevo Pedido</p>
                        </div>
                      </a>
                    </li>
                    <li className="glide__slide">
                      <a href="/clientes-agregar" className="card">
                        <div className="card-body text-center">
                          <i className="simple-icon-user-follow" />
                          <p className="card-text mb-0">Nuevo Cliente</p>
                        </div>
                      </a>
                    </li>
                    <li className="glide__slide">
                      <a href="/logout" className="card">
                        <div className="card-body text-center">
                          <i className="simple-icon-logout" />
                          <p className="card-text mb-0">Cerrar sesión</p>
                        </div>
                      </a>
                    </li>
                    {/*<li className="glide__slide">
                      <a href="#" className="card">
                        <div className="card-body text-center">
                          <i className="iconsminds-mail-read" />
                          <p className="card-text mb-0">New Comments</p>
                          <p className="lead text-center">25</p>
                        </div>
                      </a>
                    </li>*/}
                  </ul>
                </div>
              </div>
            </div>
            <div className="card dashboard-search" style={{ height: 480 }}>
              <div className="card-body">
                <h5 className="text-white mb-3">Compartir</h5>
                <div className="form-container" style={{ height: 340 }}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-5">
                      <label>Mensaje</label>
                      <div className="input-group">
                        <textarea
                          className="form-control"
                          rows={2}
                          defaultValue={""}
                          {...register("mensaje", {
                            required: true,
                          })}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-5">
                      <label>Cargar imagen</label>
                      <div>
                        <label
                          className="btn btn-outline-primary btn-upload"
                          htmlFor="inputImage"
                          title="Cargue archivo de imagen"
                        >
                          <input
                            id="inputImage"
                            type="file"
                            className="sr-only"
                            accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                          Seleccione archivo de imagen
                        </label>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <button
                        disabled={sinActividad}
                        type="submit"
                        className="btn btn-primary btn-lg mt-3"
                      >
                        Compartir
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7 col-xl-7 col-right">
            <div className="card-body">
              <h5 className="card-title">Compartidos</h5>
              <div
                className="scroll dashboard-list-with-thumbs"
                style={{ height: 850 }}
              >
                {publicaciones.map((value3, key3) => (
                  <div key={key3} className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex flex-row mb-3">
                        <img
                          src={value3.users.image}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                        <div className="pl-3">
                          <p className="font-weight-medium mb-0 ">
                            {value3.users.name}
                          </p>
                          <p className="text-muted mb-0 text-small">
                            {value3.fecha}
                          </p>
                        </div>
                      </div>
                      <p>{value3.mensaje}</p>
                      {value3.imagen != "" && (
                        <img
                          className="img-fluid border-0 border-radius mb-3"
                          src={value3.imagen_path}
                        />
                      )}
                      <div>
                        <div
                          className="post-icon mr-3 d-inline-block"
                          onClick={() => sumarLikePublicacion(value3.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="simple-icon-heart mr-1" />
                          <span>{value3.likes} Likes</span>
                        </div>
                        <div className="post-icon d-inline-block">
                          <i className="simple-icon-bubble mr-1" />{" "}
                          <span>{value3.comentarios.length} Comentarios</span>
                        </div>
                      </div>
                      <div className="mt-5">
                        {value3.comentarios.length > 0 &&
                          value3.comentarios.map((value4, key4) => (
                            <div
                              key={key4}
                              className="d-flex flex-row mb-3 border-bottom justify-content-between"
                            >
                              <img
                                src={value4.users.image}
                                className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                              />
                              <div className="pl-3 flex-grow-1">
                                <p className="font-weight-medium mb-0 ">
                                  {value4.users.name}
                                </p>
                                <p className="text-muted mb-0 text-small">
                                  {value4.fecha}
                                </p>
                                <p className="mt-3">{value4.mensaje}</p>
                              </div>
                              <div
                                className="comment-likes"
                                onClick={() => sumarLikeComentario(value4.id)}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="post-icon">
                                  <span>{value4.likes} Likes</span>
                                  <i className="simple-icon-heart ml-2" />
                                </span>
                              </div>
                            </div>
                          ))}
                        <EnviarComentario
                          publicacionId={value3.id}
                          consultarPublicaciones={consultarPublicaciones}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="form-group text-center">
                  <button
                    className="btn btn-primary btn-lg mt-3"
                    type="button"
                    onClick={irTodasPublicaciones}
                  >
                    Ir a todas las publicaciones
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
