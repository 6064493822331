import { useState, useEffect } from "react";
import axiosClient from "../axios-client.js";
import EnviarComentario from "../componentes/EnviarComentario.jsx";
import { useForm } from "react-hook-form";

function Publicaciones() {
  const [publicaciones, setPublicaciones] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sinActividad, setSinActividad] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (payload) => {
    setSinActividad(true);
    axiosClient
      .post(
        `/crear-publicacion`,
        {
          mensaje: payload.mensaje,
          file: selectedFile,
          id_user: localStorage.getItem("ID_USER")
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        consultarPublicaciones();
        setSinActividad(false);
      });
  };

  const sumarLikePublicacion = (idPublicacion) => {
    let payload = {
      id: idPublicacion,
    };
    axiosClient.post("/sumar-like-publicacion", payload).then(({ data }) => {
      consultarPublicaciones();
    });
  };

  const sumarLikeComentario = (idComentario) => {
    let payload = {
      id: idComentario,
    };
    axiosClient.post("/sumar-like-comentario", payload).then(({ data }) => {
      consultarPublicaciones();
    });
  };

  const consultarPublicaciones = () => {
    axiosClient
      .get("/obtener-publicaciones")
      .then(({ data }) => {
        setPublicaciones(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  };

  useEffect(() => {
    consultarPublicaciones();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-xl-4">
            <div className="card dashboard-search" style={{ height: 500 }}>
              <div className="card-body">
                <h5 className="text-white mb-3">Compartir</h5>
                <div className="form-container" style={{ height: 360 }}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-5">
                      <label>Mensaje</label>
                      <div className="input-group">
                        <textarea
                          className="form-control"
                          rows={4}
                          defaultValue={""}
                          {...register("mensaje", {
                            required: true,
                          })}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-5">
                      <label>Cargar imagen</label>
                      <div>
                        <label
                          className="btn btn-outline-primary btn-upload"
                          htmlFor="inputImage"
                          title="Cargue archivo de imagen"
                        >
                          <input
                            id="inputImage"
                            type="file"
                            className="sr-only"
                            accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                          Seleccione archivo de imagen
                        </label>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <button
                        disabled={sinActividad}
                        type="submit"
                        className="btn btn-primary btn-lg mt-3"
                      >
                        Compartir
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8 col-right">
            <div className="card-body">
              <h5 className="card-title">Compartidos</h5>
              <div
                className="scroll dashboard-list-with-thumbs"
                style={{ height: 850 }}
              >
                {publicaciones.map((value3, key3) => (
                  <div key={key3} className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex flex-row mb-3">
                        <img
                          src={value3.users.image}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                        <div className="pl-3">
                          <p className="font-weight-medium mb-0 ">
                            {value3.users.name}
                          </p>
                          <p className="text-muted mb-0 text-small">
                            {value3.fecha}
                          </p>
                        </div>
                      </div>
                      <p>{value3.mensaje}</p>
                      {value3.imagen != "" && (
                        <img
                          className="img-fluid border-0 border-radius mb-3"
                          src={value3.imagen_path}
                        />
                      )}
                      <div>
                        <div
                          className="post-icon mr-3 d-inline-block"
                          onClick={() => sumarLikePublicacion(value3.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="simple-icon-heart mr-1" />
                          <span>{value3.likes} Likes</span>
                        </div>
                        <div className="post-icon d-inline-block">
                          <i className="simple-icon-bubble mr-1" />{" "}
                          <span>{value3.comentarios.length} Comentarios</span>
                        </div>
                      </div>
                      <div className="mt-5">
                        {value3.comentarios.length > 0 &&
                          value3.comentarios.map((value4, key4) => (
                            <div
                              key={key4}
                              className="d-flex flex-row mb-3 border-bottom justify-content-between"
                            >
                              <img
                                src={value4.users.image}
                                className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                              />
                              <div className="pl-3 flex-grow-1">
                                <p className="font-weight-medium mb-0 ">
                                  {value4.users.name}
                                </p>
                                <p className="text-muted mb-0 text-small">
                                  {value4.fecha}
                                </p>
                                <p className="mt-3">{value4.mensaje}</p>
                              </div>
                              <div
                                className="comment-likes"
                                onClick={() => sumarLikeComentario(value4.id)}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="post-icon">
                                  <span>{value4.likes} Likes</span>
                                  <i className="simple-icon-heart ml-2" />
                                </span>
                              </div>
                            </div>
                          ))}
                        <EnviarComentario
                          publicacionId={value3.id}
                          consultarPublicaciones={consultarPublicaciones}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Publicaciones;
