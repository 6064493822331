import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../componentes/Header.jsx";
import Menu from "../componentes/Menu.jsx";
import Footer from "../componentes/Footer.jsx";

export default function General(props) {



  const token = localStorage.getItem('ACCESS_TOKEN');

  if(!token)
  {
    window.location.href = "/login";
  }

  return (
    <div>
        <Header />
        <Menu permisosAsignados={props.permisosAsignados}/>
        <Outlet />
        <Footer />
    </div>
  );
}
