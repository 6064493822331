import React, { createRef, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function NuevoPago(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('cartera_add') && permisos!='-1'){
    window.location = '/home';
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [nombreCliente, setNombreCliente] = useState([]);
  const [idCliente, setIDCliente] = useState([]);

  const [totalFactura, setTotalFactura] = useState([]);
  const [totalAbonos, setTotalAbonos] = useState([]);
  const [totalRestante, setTotalRestante] = useState([]);

  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [numeroDocumentoInd ,setNumeroDocumentoInd] = useState([]);
  const [totalRestanteSinFormato, setTotalRestanteSinFormato] = useState([]);

  const [tipoPago, setTipoPago] = useState([]);

  const id = useParams();
  const valorPagado = createRef();
  const tipoPagoRef = createRef();
  const fechaPagoRef = createRef();

  const consultarTipoPago = () => {
    let payload = {
      tabla: 'cartera',
      id_group: '1'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setTipoPago(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const consultarDetalleFactura = () => {
    let payload = {
      id_registro: id.id
    }
    axiosClient.post('/detalle-documento', payload)
    .then(({data}) => {
      setNombreCliente(data[0].nombre)
      setIDCliente(data[0].id_cliente)
      setTotalFactura(data[0].total_formato)
      setTotalAbonos(data[0].abonos)
      setTotalRestante(data[0].restante)
      setTipoDocumento(data[0].tipo_documento)
      setNumeroDocumentoInd(data[0].numero_documento_ind)
      setTotalRestanteSinFormato(data[0].restante_sinformato)
    })
  }

  const onSubmit = () => {
    let payload = {
      id_documento: id.id,
      id_cliente: idCliente,
      id_user: localStorage.getItem('ID_USER'),
      valor_pagado: valorPagado.current.value,
      tipo_pago: tipoPagoRef.current.value,
      fecha_pago: fechaPagoRef.current.value
    }

    let valor = parseInt(valorPagado.current.value);

    if(Number.isInteger(valor) && valor>0){
      if(valor>totalRestanteSinFormato){
        Swal.fire({
          icon: 'info',
          text: 'El valor no puede ser superior a la diferencia'
        })
      }else{
        axiosClient.post('/guardar-pago', payload)
        .then(({data}) => {
          window.location ='/cartera-detalle-factura/'+id.id
        })
      }
    }else{
      Swal.fire({
        icon: 'info',
        text: 'El valor debe ser numérico y mayor a 0'
      })
    }
  }

  useEffect(() => {
    consultarDetalleFactura();
    consultarTipoPago();
  }, [])
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Nuevo Pago</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="btn btn-primary rounded col-lg-7" >
        <h5>
          FACTURA: <strong>{totalFactura}</strong>&nbsp;&nbsp;&nbsp;&nbsp; ABONOS:&nbsp;
          <strong>{totalAbonos}</strong>&nbsp;&nbsp;&nbsp;&nbsp; SALDO:&nbsp;
          <strong>{totalRestante}</strong>
        </h5>
      </div><br></br><br></br>
        <div className="row ">
          <div className="col-12 col-md-12 col-xl-7 ">
            <div className="card mb-4 justify-content-center">
              {/*<div className="lightbox">
                <img
                  alt="detail"
                  src="/img/products/producto.jpeg"
                  className="responsive border-0 card-img-top mb-3"
                />
              </div>*/}
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group has-float-label">
                    <label>Cliente</label>
                    <input
                      type="text"
                      className="form-control"
                      name="jQueryName"
                      value={nombreCliente}
                      required
                      readonly
                      disabled
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>{tipoDocumento} #</span>
                    <input
                      type="text"
                      className="form-control"
                      name="jQueryName"
                      value={numeroDocumentoInd}
                      required
                      readonly
                      disabled
                    />
                  </div>
                  
                  <div className="form-group has-float-label">
                    <span>Valor</span>
                    <input
                      type="number"
                      className="form-control"
                      name="jQueryName"
                      ref={valorPagado}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>Forma de pago</span>
                    <select
                      className="form-control select2-single"
                      required
                      data-width="100%"
                      ref={tipoPagoRef}
                    >
                      <option value=""></option>
                      {tipoPago.map((tipo) =>(
                        <option key={tipo.id} value={tipo.nombre}>
                          {tipo.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <span>Fecha pago</span>
                    <input
                      type="date"
                      className="form-control"
                      ref={fechaPagoRef}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block mb-1"
                  >
                    AGREGAR PAGO
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </main>
  );
}

export default NuevoPago;
