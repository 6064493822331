import React, { useState, useEffect } from "react";
import axiosClient from "../../axios-client";

function TodosClientes(props) {

  let permisos = props.permisosAsignados;

  if(!permisos.includes('clientes_view') && permisos!='-1'){
    window.location = '/home';
  }

  const [clientes, setClientes] = useState([]);

  const dataClientes = () =>{
    let payload;
    axiosClient.post('/obtener-clientes', payload)
    .then(({ data }) => {
        setClientes(data);
    }).catch(function (error) {
        console.log(error);
    });
  }

  const irDetalle = (id) => {
    window.location = "/clientes-detalles/"+id;
  };

  const irAgregar = () => {
    window.location = "/clientes-agregar";
  };

  useEffect(() => {
    dataClientes();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Todos los Clientes</h1>
            {permisos.includes('clientes_add') || permisos=='-1' ?
            <div className="top-right-button-container">
              <div className="btn-group">
                <button className="btn btn-primary" type="button" onClick={irAgregar}>
                  AGREGAR
                </button>
              </div>
            </div>
            : '' }
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <br></br><br></br>
            <table
              id=""
              className="data-table responsive nowrap cont_tabla"
              data-order='[[ 0, "asc" ]]'
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Razón social</th>
                  <th>Celular</th>
                  <th>Nombre</th>
                  <th>Ciudad</th>
                </tr>
              </thead>
              <tbody>
                { clientes.map((registro, i) => (
                    <tr key={i}>
                      <td>
                        <div className="icon-cards-row">
                          <p className="lead mt-2">{registro.id}</p>
                        </div>
                      </td>
                      <td onClick={() => irDetalle(registro.id)} className="activatePointer">
                        <p className="text-muted">{registro.razon_social}</p>
                        <p className="">{registro.tipo_documento}: {registro.numero_documento}</p>
                      </td>
                      <td>
                        <p className="text-muted">{registro.celular}</p>
                        <a href={"https://wa.me/" + registro.celular}>
                          <i className="simple-icon-bubble align-middle p-3 heading-icon" />
                        </a>
                        <a href={"tel:" + registro.celular}>
                          <i className="simple-icon-call-in align-middle heading-icon" />
                        </a>
                      </td>
                      <td className="activatePointer" onClick={() => irDetalle(registro.id)}>
                        <p className="list-item-heading">{registro.nombre}</p>
                        <p className="">
                          {registro.email}
                          <a href={"mailto:" + registro.email}>
                            <i className="iconsminds-mail-with-cursors align-middle p-2 heading-icon" />
                          </a>
                        </p>
                      </td>
                      <td>
                        <p className="text-muted">{registro.ciudad}</p>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
}

export default TodosClientes;
