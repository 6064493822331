import React from 'react'
import { useState } from 'react';
import axiosClient from '../../axios-client';
import { useEffect } from 'react';
import $ from 'jquery';

function ArticulosPublicados(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('paginaweb_view') && permisos!='-1'){
    window.location = '/home';
  }

  const [productos, setProductos] = useState([]);

  const dataProductos = () =>{
    let payload;
    axiosClient.get('/obtener-productos', payload)
    .then(({ data }) => {
      setProductos(data);
    }).catch(function (error) {
        console.log(error);
    });
  }

  const publicarProducto = (id, estado) => {
    let payload = {
      id_producto: id,
      estado
    }
    axiosClient.post('/publicar-producto', payload)
    .then(({data}) => {

      if(estado=='1'){
        $(".despublicar"+id).removeClass('ocultar');
        $(".publicar"+id).addClass('ocultar');
        $(".fecha"+id).html(data.fecha)
        $(".etiqueta"+id).html("Publicado")
      }else if(estado=='0'){
        $(".despublicar"+id).addClass('ocultar');
        $(".publicar"+id).removeClass('ocultar');
        $(".fecha"+id).html("")
        $(".etiqueta"+id).html("")
      }

    })
  }

  useEffect(() => {
    dataProductos();
  }, []);
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Todos los Artículos publicados</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <table className="data-table responsive cont_tabla">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Código</th>
                      <th>Producto</th>
                      <th>Fecha de Publicación</th>
                      <th>Estado</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    { productos.map((registro, i) => (
                      <tr key={i}>
                        <td>{registro.id}</td>
                        <td>{registro.codigo}</td>
                        <td>{registro.titulo}</td>
                        <td className={"fecha"+registro.id}>{registro.fecha_publicacion}</td>
                        <td>
                          <div className="col-12">
                            <div className="custom-switch custom-switch-primary mb-2 custom-switch-small">
                              {registro.estado=='1' ? <label className={"badge badge-success etiqueta"+registro.id}>Publicado</label> : <label className={"badge badge-success etiqueta"+registro.id}></label>}
                            </div>
                          </div>
                        </td>
                        <td>
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Accion
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop1"
                        >
                          <a className={registro.estado=='1' ? 'ocultar dropdown-item activatePointer publicar'+registro.id : 'dropdown-item activatePointer publicar'+registro.id}  onClick={() => publicarProducto(registro.id, '1')}>Publicar</a>
                          <a className={registro.estado=='0' ? 'ocultar dropdown-item activatePointer despublicar'+registro.id : 'dropdown-item activatePointer despublicar'+registro.id}  onClick={() => publicarProducto(registro.id, '0')}>Despublicar</a>
                          <a className="dropdown-item" href={"/productos-detalles/"+registro.id}>Editar</a>
                          <a className="dropdown-item" href={"/productos-galeria/"+registro.id}>Galería</a>
                        </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ArticulosPublicados