import React, { useState, useEffect, createRef } from "react";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import axiosClient from "../../axios-client";

function AgregarProducto(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('productos_add') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [color, setColor] = useState([]);
  const [material, setMaterial] = useState([]);
  const [empaque, setEmpaque] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  const codigoRef = createRef();
  const tituloRef = createRef();
  const descripcionRef = createRef();
  const colorRef = createRef();
  const materialRef = createRef();
  const empaqueRef = createRef();
  const ubicacionRef = createRef();

  const consultarColor = () => {
    let payload = {
      tabla: 'productos',
      id_group: '1'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setColor(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const consultarMaterial = () => {
    let payload = {
      tabla: 'productos',
      id_group: '2'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setMaterial(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const consultarEmpaque = () => {
    let payload = {
      tabla: 'productos',
      id_group: '3'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setEmpaque(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const onSubmit = (data) => {
    let payload = {
      codigo: codigoRef.current.value,
      titulo: tituloRef.current.value,
      descripcion: descripcionRef.current.value,
      color: colorRef.current.value,
      material: materialRef.current.value,
      empaque: empaqueRef.current.value,
      ubicacion: ubicacionRef.current.value,
      file: selectedFile
    }
    axiosClient.post("/agregar-producto", 
      payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(({ data }) => {
        if(data.icon=='error'){
          Swal.fire({
              icon: data.icon,
              text: data.message
          })
        }else{
            window.location = "/productos-todos";
        }
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  };

  useEffect(() => {
    consultarColor();
    consultarMaterial();
    consultarEmpaque();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Nuevo Registro de Producto</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-xl-7 ">
            <div className="card mb-4 justify-content-center">
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group has-float-label">
                    <label>Código</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={codigoRef}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Título</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={tituloRef}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Descripción</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      ref={descripcionRef}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>Color</span>
                    <select
                      className="form-control select2-single"
                      ref={colorRef}
                      required
                      data-width="100%"
                    >
                      <option />
                      { color.map((registro, i) =>(
                        <option value={registro.id}>{registro.nombre}</option> 
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <span>Material</span>
                    <select
                      className="form-control select2-single"
                      ref={materialRef}
                      required
                      data-width="100%"
                    >
                      <option />
                      { material.map((registro, i) =>(
                        <option value={registro.id}>{registro.nombre}</option> 
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <span>Empaque</span>
                    <select
                      className="form-control select2-single"
                      name="jQueryTopLabelsState"
                      required
                      data-width="100%"
                      ref={empaqueRef}
                    >
                      <option />
                      { empaque.map((registro, i) =>(
                        <option value={registro.id}>{registro.nombre}</option> 
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <label>Ubicación</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={ubicacionRef}
                    />
                  </div>
                  {/*<div className="form-group has-float-label">
                    <label
                      className="btn btn-outline-primary btn-upload"
                      htmlFor="inputImage"
                      title="Cargue archivo de imagen"
                    >
                      <input
                        id="inputImage"
                        type="file"
                        className="sr-only"
                        accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                      Cargar foto de producto
                    </label>
                    &nbsp;<label>{selectedFile ? selectedFile.name : ''}</label>
                  </div>*/}
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block mb-1"
                  >
                    CREAR PRODUCTO
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AgregarProducto;
