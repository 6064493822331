import React, { useState, useEffect, createRef } from "react";
import axiosClient from "../../axios-client";
import Swal from 'sweetalert2';
import $ from 'jquery';
import { NumericFormat } from 'react-number-format';
import { useParams } from "react-router-dom";

function EditarPedido(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('pedidos_edit') && permisos!='-1'){
    window.location = '/home';
  }

  const [clientes, setClientes] = useState([]);
  const [cartera, setCartera] = useState([]);
  const [productos, setProductos] = useState([]);
  const [nombreCliente, setNombreCliente] = useState([]);
  const [razonSocial, setRazonSocial] = useState([]);
  const [productosPedido, setProductosPedido] = useState([]);
  const [negociacion, setNegociacion] = useState([]);
  const [negociacionCliente, setNegociacionCliente] = useState([]);

  const clienteSeleccionado = createRef();
  const productoSeleccionado = createRef();

  const notasRef = createRef();
  const [clienteModificado, setClienteModificado] = useState(false);
  const [userPedido, setUserPedido] = useState('');

  const { id } = useParams();

  const dataProductos = () =>{
    let payload;
    axiosClient.get('/obtener-productos-precio', payload)
    .then(({ data }) => {
      setProductos(data);
    }).catch(function (error) {
        console.log(error);
    });
  }

  const dataClientes = () =>{
    let payload;
    axiosClient.post('/obtener-clientes', payload)
    .then(({ data }) => {
      setClientes(data);
    }).catch(function (error) {
        console.log(error);
    });
  }

  const dataPedido = () => {
    let payload = {
        id_pedido: id
    }
    axiosClient.post('/detalle-pedido-editar', payload)
    .then(({data})=>{
        setNombreCliente(data[0].nombreCliente)
        setRazonSocial(data[0].razon_social)
        setProductosPedido(data[0].items)
        setNegociacion(data[0].negociacion)
        setNegociacionCliente(data[0].negociacionCliente)
        if(data[0].id_users!=localStorage.getItem('ID_USER')){
          if(permisos!="-1"){
            window.location = "/pedidos-mios";
          }
        }
    })
  }

  const agregarProducto = () =>{
    let cantidad = $("#cantidad").val();
    if(cantidad){
      let payload = {
        id: productoSeleccionado.current.value
      }
      axiosClient.post('/obtener-producto', payload)
      .then(({ data }) => {
        $("#cantidad").val('');
        $(".select2-selection__rendered").empty();
        
        setProductosPedido([
          ...productosPedido,
          {id_productos: productoSeleccionado.current.value, codigo: data[0].codigo, titulo: data[0].titulo, precio: data[0].precio, cantidad: cantidad}
        ])
      }).catch(function (error) {
          console.log(error);
      });
    }else{
      Swal.fire({
        icon: 'info',
        text: 'Debe seleccionar producto y cantidad'
      })
    }
  }

  const enviarPedido = () => {
    let payload = {
      productos: productosPedido,
      id_user: localStorage.getItem("ID_USER"),
      id_pedido: id
    }
    axiosClient.post('/editar-pedido', payload)
    .then(({data}) => {
      window.location = '/pedidos-todos';
    })
  }

  const eliminarProducto = (codigo) => {
    setProductosPedido(
        productosPedido.filter(a => a.codigo !== codigo)
    );
  }

  useEffect(() => {
    dataClientes();
    dataProductos();
    dataPedido();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Editar Pedido</h1>
            <div className="separator mb-5" />
          </div>
          <div className="col-12 mb-4">
            <div className="card mb-4">
              <div id="smartWizardCustomButtons">
                <ul className="card-header">
                  <li>
                    <a href="#customButtons3">
                      Paso 1<br />
                      <small>Seleccionar Productos</small>
                    </a>
                  </li>
                  <li>
                    <a href="#customButtons4">
                      Paso 2<br />
                      <small>Enviar Pedido</small>
                    </a>
                  </li>
                </ul>
                <div className="card-body">
                  <div id="customButtons3">
                    <h4 className="pb-2">{razonSocial ? razonSocial+' - ' : ''}{nombreCliente}</h4>
                    <select
                      className="form-control select2-single select2-hidden-accessible productos"
                      data-width="100%"
                      tabIndex={-1}
                      aria-hidden="true"
                      ref={productoSeleccionado}
                      defaultValue={productoSeleccionado}
                    >
                      <option value="0"></option>
                      {productos.map((registro, i) => (
                        <option value={registro.id} key={i}>{registro.codigo} - {registro.titulo}</option>
                      ))}
                    </select>
                    <div className="input-group typeahead-container">
                      <input
                        type="text"
                        className="form-control typeahead"
                        id="cantidad"
                        placeholder="Cantidad"
                        data-provide="typeahead"
                        autoComplete="off"
                      />
                      <div className="input-group-append ">
                        <button
                          type="button"
                          className="btn btn-primary default"
                          onClick={() => agregarProducto()}
                        >
                          Agregar
                        </button>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Pedido</h5>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Codigo</th>
                              <th scope="col">Producto</th>
                              <th scope="col">Precio</th>
                              <th scope="col">Cantidad</th>
                              <th scope="col">Acción</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productosPedido.map((registro, i) => (
                              <tr key={i}>
                                <td>{registro.codigo}</td>
                                <td>{registro.titulo}</td>
                                <td><NumericFormat value={registro.precio} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                <td>{registro.cantidad}</td>
                                <td>
                                    <button className="btn btn-danger" onClick={() => eliminarProducto(registro.codigo)}>
                                        Eliminar
                                    </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <br />
                        <br />
                        {/*<h5 className="card-title">Total: $</h5>*/}
                      </div>
                    </div>
                  </div>
                  <div id="customButtons4">
                    <p><strong>Negociación del cliente: </strong>{negociacionCliente}</p>
                    <p><strong>Negociación del pedido: </strong>{negociacion}</p>
                    <form>
                      <br />
                      <br />
                      <a>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={()=>enviarPedido()}
                        >
                          <h4>Confirmar y Enviar Pedido</h4>
                        </button>
                      </a>
                    </form>
                  </div>
                </div>
                <div className="btn-toolbar custom-toolbar text-center card-body pt-0">
                  <button className="btn btn-secondary prev-btn" type="button">
                    Previo
                  </button>&nbsp;
                  <button className="btn btn-secondary next-btn" type="button">
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default EditarPedido;
