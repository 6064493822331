import React from 'react'

function CrearLista() {
  return (
    <main>
      <div className="container-fluid">
        Crear Lista de Precios
      </div>
    </main>
  )
}

export default CrearLista