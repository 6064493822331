import {useState} from 'react'
import axiosClient from '../axios-client';
 
const EnviarComentario = (props) => {
  const { publicacionId, consultarPublicaciones } = props;
  const [comentario, setComentario] = useState('');

  const onChange = (e) => {
    setComentario(e.target.value);
  };

  const guardarComentario = (publicacionId) => {
    let payload = {
      mensaje: comentario,
      publicacion_id: publicacionId,
      id_user: localStorage.getItem("ID_USER")
    };
    axiosClient.post("/crear-comentario", payload).then(({ data }) => {
      setComentario('');
      consultarPublicaciones();
    });
  };
 
  return (
    <div className="comment-contaiener">
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Añadir comentario"
          onChange={onChange}
          value={comentario}
        />
        <div className="input-group-append">
          <button className="btn btn-secondary" type="button" onClick={() => guardarComentario(publicacionId)}>
            <span className="d-inline-block">Enviar</span>
            <i className="simple-icon-arrow-right ml-2" />
          </button>
        </div>
      </div>
    </div>
  )
}
 
export default EnviarComentario