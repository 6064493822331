import React, { useEffect, useState, createRef } from "react";
import { useParams } from "react-router-dom";
import axiosClient from "../../axios-client";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';

function DetalleCliente(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('clientes_edit') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const { id } = useParams();
  const [ciudades, setCiudades] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [notas, setNotas] = useState([]);

  const razonSocialRef = createRef();
  const nombreRef = createRef();
  const direccionRef = createRef();
  const emailRef = createRef();
  const celularRef = createRef();
  const negociacionRef = createRef();
  const numeroRef = createRef();
  const notaRef = createRef();
  

  //campos
  const [razon_social, setRazonSocial] = useState('');
  const [celular, setCelular] = useState('');
  const [direccion, setDireccion] = useState('');
  const [email, setEmail] = useState('');
  const [negociacion, setNegociacion] = useState('');
  const [nombre, setNombre] = useState('');
  const [numero_documento, setNumeroDocumento] = useState('');
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState('');
  const [ciudadSeleccionada, setCiudadSeleccionada] = useState('');

  const handleChange = (event) => {
    setCiudadSeleccionada(event.target.value);
  };

  const handleChange2 = (event) => {
    setTipoDocumentoSeleccionado(event.target.value);
  };

  const eliminarElemento = (id_registro) => {
    Swal.fire({ 
      text: "Confirmar eliminación de registro", 
      icon: "info", 
      showCancelButton: !0, 
      confirmButtonText: "Enviar", 
      confirmButtonColor: '#008E8A', 
      cancelButtonText: "Cancelar", 
      cancelButtonColor: '#d70021' 
    }).then((e) => {
      if(e.isConfirmed){
        let payload = {
          id_registro
        }
        axiosClient.post("/eliminar-cliente", payload)
          .then(({ data }) => {
          window.location = "/clientes-todos";
        })
        .catch((err) => {
          const response = err.response;
          console.log(response);
        });
      }
    });
  };

  const onSubmitNota = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_cliente: id,
      nota: notaRef.current.value
    }
    axiosClient.post("/agregar-nota-cliente", payload)
      .then(({ data_ }) => {
      window.location = "/clientes-detalles/"+id;
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  const onSubmit = (data) => {
    let payload = {
      ciudad: ciudadSeleccionada,
      tipo_documento: tipoDocumentoSeleccionado,
      empresa: razonSocialRef.current.value,
      nombre: nombreRef.current.value,
      direccion: direccionRef.current.value,
      email: emailRef.current.value,
      celular: celularRef.current.value,
      negociacion: negociacionRef.current.value,
      numero_documento: numeroRef.current.value,
      id_registro: id,
      id_user: localStorage.getItem("ID_USER")
    }
    axiosClient.post("/actualizar-cliente", payload)
      .then(({ data_ }) => {
      window.location = "/clientes-todos";
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  };

  const consultarCiudades = () => {
    let payload = {
      tabla: 'ciudades',
      id_group: '1'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setCiudades(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const consultarTipoDocumento = () => {
    let payload = {
      tabla: 'tipo_documento',
      id_group: '2'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setTipoDocumento(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const consultarNotas = () => {
    let payload = {
      id_cliente: id
    }
    axiosClient
    .post("/notas-cliente", payload)
    .then(({ data }) => {
      setNotas(data);
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  const dataCliente = () =>{
    axiosClient.post('/obtener-cliente', {id: id})
    .then(({ data }) => {
      setRazonSocial(data[0].razon_social)
      setNombre(data[0].nombre);
      setCiudadSeleccionada(data[0].ciudad_id);
      setTipoDocumentoSeleccionado(data[0].tipo_documento_id);
      setNumeroDocumento(data[0].numero_documento);
      setDireccion(data[0].direccion);
      setCelular(data[0].celular);
      setEmail(data[0].email);
      setNegociacion(data[0].negociacion);
    }).catch(function (error) {
        console.log(error);
    });
  }

  useEffect(() => {
    consultarCiudades();
    consultarTipoDocumento();
    dataCliente();
    consultarNotas();
  }, []);
  

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="mb-2">
              <h1>Detalle del Cliente</h1>
            </div>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-xl-7 col-left">
            <div className="card mb-4">
              <div className="lightbox">
                <img
                  alt="detail"
                  src="../img/fotoCliente.jpeg"
                  className="responsive border-0 card-img-top mb-3"
                />
              </div>
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group has-float-label">
                    <label>Empresa</label>
                    <input
                      type="text"
                      className="form-control"
                      name="empresa"
                      value={razon_social}
                      onChange={e => setRazonSocial(e.target.value)}
                      ref={razonSocialRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre"
                      {...register("nombre")}
                      required
                      value={nombre}
                      onChange={nom => setNombre(nom.target.value)}
                      ref={nombreRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Tipo de documento</label>
                    <select className="form-control"
                      required
                      onChange={handleChange2}
                      value={tipoDocumentoSeleccionado}
                    >
                      <option value=""></option>
                      {tipoDocumento.map((tipo) =>(
                        <option key={tipo.id} value={tipo.id}>
                          {tipo.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <label>Número de Documento</label>
                    <input
                      type="text"
                      className="form-control"
                      name="numeroDocumento"
                      {...register("numero_documento")}
                      required
                      value={numero_documento}
                      onChange={e => setNumeroDocumento(e.target.value)}
                      ref={numeroRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Dirección</label>
                    <input
                      type="text"
                      className="form-control"
                      name="direccion"
                      {...register("direccion")}
                      required
                      value={direccion}
                      onChange={e => setDireccion(e.target.value)}
                      ref={direccionRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>Ciudad</span>
                    <select className="form-control"
                      required
                      onChange={handleChange}
                      value={ciudadSeleccionada}
                    >
                      <option value=""></option>
                      {ciudades.map((ciudad) =>(
                        <option key={ciudad.id} value={ciudad.id}>
                          {ciudad.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <label>Celular</label>
                    <input
                      type="text"
                      className="form-control"
                      name="celular"
                      {...register("celular")}
                      required
                      value={celular}
                      onChange={e => setCelular(e.target.value)}
                      ref={celularRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>Correo</span>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      {...register("email")}
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      ref={emailRef}
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Negociación</label>
                    <input
                      type="text"
                      className="form-control"
                      name="negociacion"
                      {...register("negociacion")}
                      required
                      value={negociacion}
                      onChange={e => setNegociacion(e.target.value)}
                      ref={negociacionRef}
                    />
                  </div>
                  {permisos.includes('clientes_edit') || permisos=='-1' ?
                  <button className="btn btn-primary" type="submit">
                    Actualizar información
                  </button>
                  : '' }
                  &nbsp;
                  {permisos.includes('clientes_delete') || permisos=='-1' ?
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => eliminarElemento(id)}
                  >
                    <i className="glyph-icon simple-icon-trash" />
                  </button>
                  : '' }
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-5 col-right">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Notas:</h5>
                <div className="mt-5">
                  {notas.map((value, key) => (
                    <div key={key} className="d-flex flex-row mb-3 border-bottom justify-content-between">
                      <a>
                        <img
                           src={value.users.image}
                          alt={value.users.name}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                      </a>
                      <div className="pl-3 flex-grow-1">
                        <p className="list-item-heading mb-0 ">
                          {value.users.name} &nbsp;
                          <span className="text-muted mb-0 text-small">
                            {value.fecha}
                            <i className="simple-icon-clock mr-1" />
                            {value.hora}
                          </span>{" "}
                        </p>
                        <p className="mt-3">
                          <i className="simple-icon-bubble mr-1 heading-icon" />
                          {value.nota}
                        </p>
                      </div>
                    </div>
                  ))}

                  <form onSubmit={handleSubmit(onSubmitNota)}>
                    <div className="comment-contaiener">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agregar Nota"
                          ref={notaRef}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="submit">
                            <span className="d-inline-block">Guardar</span>
                            <i className="simple-icon-arrow-right ml-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DetalleCliente;
