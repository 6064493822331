import React from "react";
import axiosClient from "../axios-client.js";

function Menu(props) {

  let permisos = props.permisosAsignados;

  return (
    <div className="menu">
      <div className="main-menu">
        <div className="scroll">
          <ul className="list-unstyled">
            <li className="active">
              <a href="/home">
                <i className="simple-icon-home" />
                <span>Dashboard</span>
              </a>
            </li>
            {permisos.includes("clientes_view") || permisos=="-1" ?
              <li>
                <a href="#clientes">
                  <i className="iconsminds-male-female" />
                  <span>Clientes</span>
                </a>
              </li>
            : '' }
            {permisos.includes("productos_view") || permisos=="-1" ?
              <li>
                <a href="#productos">
                  <i className="iconsminds-diamond" /> Productos
                </a>
              </li>
            : ''}
            {permisos.includes("planos_view") || permisos=="-1" ?
              <li>
                <a href="/planos-todos">
                  <i className="simple-icon-map" /> Planos
                </a>
              </li>
            : '' }
            {permisos.includes("precios_view") || permisos=="-1" ?
              <li>
                <a href="/precios-lista">
                  <i className="iconsminds-pricing" /> Precios
                </a>
              </li>
            : '' }
            {permisos.includes("inventario_view") || permisos=="-1" ?
            <li>
              <a href="#inventario">
                <i className="iconsminds-box-with-folders" /> Inventario
              </a>
            </li>
            : '' }
            {permisos.includes("pedidos_view") || permisos=="-1" ?
            <li>
              <a href="#pedidos">
                <i className="iconsminds-notepad" /> Pedidos
              </a>
            </li> 
            : '' }           
            {permisos.includes("produccion_view") || permisos=="-1" ?
            <li>
              <a href="#produccion">
                <i className="iconsminds-gears" /> Producción
              </a>
            </li>
            : '' }
            {permisos.includes("planta_view") || permisos=="-1" ?
            <li>
              <a href="/planta-estado">
                <i className="iconsminds-factory-1" /> Planta
              </a>
            </li>
            : '' }
            {permisos.includes("despachos_view") || permisos=="-1" ?
            <li>
              <a href="#despachos">
                <i className="iconsminds-box-close" /> Despachos
              </a>
            </li>
            : '' }
            {permisos.includes("documentacion_view") || permisos=="-1" ?
            <li>
              <a href="#documentos">
                <i className="iconsminds-letter-open" /> Documentación
              </a>
            </li>
            : '' }
            {permisos.includes("cartera_view") || permisos=="-1" ?
            <li>
              <a href="#cartera">
                <i className="iconsminds-financial" /> Cartera
              </a>
            </li>
            : '' }
            {permisos.includes("paginaweb_view") || permisos=="-1" ?
            <li>
              <a href="#pagina-web">
                <i className="iconsminds-digital-drawing" /> Página Web
              </a>
            </li>
            : '' }
            {permisos.includes("configuracion_view") || permisos=="-1" ?
            <li>
              <a href="#configuracion">
                <i className="iconsminds-gear" /> Configuración
              </a>
            </li>
            : '' }
          </ul>
        </div>
      </div>
      <div className="sub-menu">
        <div className="scroll">
          <ul className="list-unstyled" data-link="clientes">
            <li>
              <a href="/clientes-todos">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Todos los Clientes</span>
              </a>
            </li>
            <li>
              <a href="/clientes-mios">
                <i className="simple-icon-list" />{" "}
                <span className="d-inline-block">Mis Clientes</span>
              </a>
            </li>
            <li>
              <a href="/clientes-parametros">
                <i className="simple-icon-directions" />{" "}
                <span className="d-inline-block">Parámetros</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" data-link="productos">
            <li>
              <a href="/productos-todos">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Todos los Productos</span>
              </a>
            </li>
            <li>
              <a href="/productos-parametros">
                <i className="simple-icon-directions" />{" "}
                <span className="d-inline-block">Parámetros</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" data-link="pedidos">
          <li>
              <a href="/pedidos-nuevo">
                <i className="simple-icon-plus" />{" "}
                <span className="d-inline-block">Nuevo Pedido</span>
              </a>
            </li>
            <li>
              <a href="/pedidos-todos">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Todos los Pedidos</span>
              </a>
            </li>
            <li>
              <a href="/pedidos-mios">
                <i className="simple-icon-directions" />{" "}
                <span className="d-inline-block">Mis Pedidos</span>
              </a>
            </li>
            <li>
              <a href="/pedidos-archivados">
                <i className="simple-icon-folder" />{" "}
                <span className="d-inline-block">Archivados</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" data-link="produccion">
            <li>
              <a href="/produccion-control">
                <i className="iconsminds-over-time-2" />{" "}
                <span className="d-inline-block">Control de Producción</span>
              </a>
            </li>
            <li>
              <a href="/produccion-orden">
                <i className="iconsminds-refresh" />{" "}
                <span className="d-inline-block">Orden de Producción</span>
              </a>
            </li>
            <li>
              <a href="/produccion-revisar">
                <i className="iconsminds-check" />{" "}
                <span className="d-inline-block">Revisar Producción</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" data-link="despachos">
          <li>
              <a href="/despachos-nuevo">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Nuevo despacho</span>
              </a>
            </li>
            <li>
              <a href="/despachos-ver">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Ver despachos</span>
              </a>
            </li>
            <li>
              <a href="/despachos-archivados">
                <i className="simple-icon-folder" />{" "}
                <span className="d-inline-block">Archivados</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" data-link="inventario">
            <li>
              <a href="/inventario-ver">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Ver inventario</span>
              </a>
            </li>
            <li>
              <a href="/inventario-agregar-item">
                <i className="simple-icon-directions" />{" "}
                <span className="d-inline-block">Agregar ítems</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" data-link="documentos">
            <li>
              <a href="/documentar-listado">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Pendientes</span>
              </a>
            </li>
            <li>
              <a href="/documentos-realizados">
                <i className="simple-icon-folder" />{" "}
                <span className="d-inline-block">Documentados</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" data-link="cartera">
            <li>
              <a href="/cartera-pendiente">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Cartera Pendiente</span>
              </a>
            </li>
            <li>
              <a href="/cartera-parametros">
                <i className="simple-icon-directions" />{" "}
                <span className="d-inline-block">Parámetros</span>
              </a>
            </li>
            <li>
              <a href="/cartera-historial">
                <i className="simple-icon-folder" />{" "}
                <span className="d-inline-block">Historial de Cartera</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" data-link="pagina-web">
            <li>
              <a href="/web-articulos-publicados">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Artículos Publicados</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" data-link="configuracion">
            <li>
              <a href="/configuracion-mi-perfil">
                <i className="simple-icon-book-open" />{" "}
                <span className="d-inline-block">Mi Perfil</span>
              </a>
            </li>
            <li>
              <a href="/configuracion-administrar-usuarios">
                <i className="simple-icon-directions" />{" "}
                <span className="d-inline-block">Administrar Usuarios</span>
              </a>
            </li>
            <li>
              <a href="/configuracion-copia-seguridad">
                <i className="simple-icon-notebook" />{" "}
                <span className="d-inline-block">Copia de seguridad</span>
              </a>
            </li>
            <li>
              <a href="/configuracion-soporte">
                <i className="simple-icon-call-in" />{" "}
                <span className="d-inline-block">Soporte Técnico</span>
              </a>
            </li>
            <li>
              <a href="/logout">
                <i className="simple-icon-logout" />{" "}
                <span className="d-inline-block">Cerrar sesión</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Menu;
