import React, { useState, useEffect, createRef } from "react";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import axiosClient from "../../axios-client";
import { useParams } from "react-router-dom";

function DetalleProducto(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('productos_edit') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [color, setColor] = useState([]);
  const [material, setMaterial] = useState([]);
  const [empaque, setEmpaque] = useState([]);
  const [notas, setNotas] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const { id } = useParams();
  const codigoRef = createRef();
  const tituloRef = createRef();
  const descripcionRef = createRef();
  const colorRef = createRef();
  const materialRef = createRef();
  const empaqueRef = createRef();
  const notaRef = createRef();
  const ubicacionRef = createRef();

  const [codigo, setCodigo] = useState([]);
  const [titulo, setTitulo] = useState([]);
  const [descripcion, setDescripcion] = useState([]);
  const [colorSeleccionado, setColorSeleccionado] = useState('');
  const [materialSeleccionado, setMaterialSeleccionado] = useState('');
  const [empaqueSeleccionado, setEmpaqueSeleccionado] = useState('');
  const [imagen, setImagen] = useState([]);
  const [ubicacion, setUbicacion] = useState([]);

  const dataProducto = () =>{
    axiosClient.post('/obtener-producto', {id: id})
    .then(({ data }) => {
      setCodigo(data[0].codigo);
      setTitulo(data[0].titulo);
      setDescripcion(data[0].descripcion);
      setColorSeleccionado(data[0].id_color)
      setMaterialSeleccionado(data[0].id_material)
      setEmpaqueSeleccionado(data[0].id_empaque)
      setImagen(data[0].image);
      if(data[0].ubicacion!=null && data[0].ubicacion!=''){
        setUbicacion(data[0].ubicacion);
      }else{
        setUbicacion("");
      }
    }).catch(function (error) {
        console.log(error);
    });
  }

  const eliminarElemento = (id_registro) => {
    Swal.fire({ 
      text: "Confirmar eliminación de registro", 
      icon: "info", 
      showCancelButton: !0, 
      confirmButtonText: "Enviar", 
      confirmButtonColor: '#008E8A', 
      cancelButtonText: "Cancelar", 
      cancelButtonColor: '#d70021' 
    }).then((e) => {
      if(e.isConfirmed){
        let payload = {
          id_registro
        }
        axiosClient.post("/eliminar-producto", payload)
          .then(({ data }) => {
          window.location = "/productos-todos";
        })
        .catch((err) => {
          const response = err.response;
          console.log(response);
        });
      }
    });
  };

  const consultarColor = () => {
    let payload = {
      tabla: 'productos',
      id_group: '1'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setColor(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const consultarMaterial = () => {
    let payload = {
      tabla: 'productos',
      id_group: '2'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setMaterial(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const consultarEmpaque = () => {
    let payload = {
      tabla: 'productos',
      id_group: '3'
    }
    axiosClient
      .post("/parametros-generales", payload)
      .then(({ data }) => {
        setEmpaque(data);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  }

  const onSubmit = (data) => {
    let payload = {
      codigo: codigoRef.current.value,
      titulo: tituloRef.current.value,
      descripcion: descripcionRef.current.value,
      ubicacion: ubicacionRef.current.value,
      color: colorRef.current.value,
      material: materialRef.current.value,
      empaque: empaqueRef.current.value,
      id_producto: id,
      file: selectedFile
    }
    axiosClient.post("/actualizar-producto", 
      payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if(data.icon=='error'){
          Swal.fire({
              icon: data.icon,
              text: data.message
          })
        }else{
          window.location = "/productos-detalles/"+id;
        }
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  };

  const onSubmitNota = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_producto: id,
      nota: notaRef.current.value
    }
    axiosClient.post("/agregar-nota-producto", payload)
      .then(({ data_ }) => {
      window.location = "/productos-detalles/"+id;
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }
  
  const consultarNotas = () => {
    let payload = {
      id_producto: id
    }
    axiosClient
    .post("/notas-producto", payload)
    .then(({ data }) => {
      setNotas(data);
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  useEffect(() => {
    dataProducto();
    consultarColor();
    consultarMaterial();
    consultarEmpaque();
    consultarNotas();
  }, []);
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="mb-2">
              <h1>Detalle del Producto</h1>
            </div>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-xl-7 col-left">
            <div className="card mb-4">
              {/*<img
                alt="Profile"
                src={imagen}
                className="img-thumbnail card-img"
              />*/}
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group has-float-label">
                    <label>Código</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={codigoRef}
                      value={codigo}
                      onChange={e => setCodigo(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Título</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={tituloRef}
                      value={titulo}
                      onChange={e => setTitulo(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <label>Descripción</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      ref={descripcionRef}
                      value={descripcion}
                      onChange={e => setDescripcion(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>Color</span>
                    <select
                      className="form-control select2-single"
                      ref={colorRef}
                      required
                      data-width="100%"
                      value={colorSeleccionado}
                      onChange={e => setColorSeleccionado(e.target.value)}
                    >
                      <option />
                      { color.map((registro, i) =>(
                        <option value={registro.id} key={i}>{registro.nombre}</option> 
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <span>Material</span>
                    <select
                      className="form-control select2-single"
                      ref={materialRef}
                      required
                      data-width="100%"
                      value={materialSeleccionado}
                      onChange={e => setMaterialSeleccionado(e.target.value)}
                    >
                      <option />
                      { material.map((registro, i) =>(
                        <option value={registro.id} key={i}>{registro.nombre}</option> 
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <span>Empaque</span>
                    <select
                      className="form-control select2-single"
                      name="jQueryTopLabelsState"
                      required
                      data-width="100%"
                      ref={empaqueRef}
                      value={empaqueSeleccionado}
                      onChange={e => setEmpaqueSeleccionado(e.target.value)}
                    >
                      <option />
                      { empaque.map((registro, i) =>(
                        <option value={registro.id} key={i}>{registro.nombre}</option> 
                      ))}
                    </select>
                  </div>
                  <div className="form-group has-float-label">
                    <label>Ubicación</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={ubicacionRef}
                      value={ubicacion}
                      onChange={e => setUbicacion(e.target.value)}
                    />
                  </div>
                  {/*<div className="form-group has-float-label">
                    <label
                      className="btn btn-outline-primary btn-upload"
                      htmlFor="inputImage"
                      title="Cargue archivo de imagen"
                    >
                      <input
                        id="inputImage"
                        type="file"
                        className="sr-only"
                        accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                      Cargar foto de producto
                    </label>
                    &nbsp;<label>{selectedFile ? selectedFile.name : ''}</label>
                  </div>*/}
                  {permisos.includes('productos_edit') || permisos=='-1' ?
                  <button className="btn btn-primary" type="submit">
                    Actualizar información
                  </button>
                  : ''}&nbsp;
                  {permisos.includes('productos_delete') || permisos=='-1' ?
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => eliminarElemento(id)}
                  >
                    <i className="glyph-icon simple-icon-trash" />
                  </button>
                    : ''}
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-5 col-right">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Notas:</h5>
                <div className="mt-5">
                  {notas.map((value, key) => (
                    <div key={key} className="d-flex flex-row mb-3 border-bottom justify-content-between">
                      <a>
                        <img
                           src={value.users.image}
                          alt={value.users.name}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                      </a>
                      <div className="pl-3 flex-grow-1">
                        <p className="list-item-heading mb-0 ">
                          {value.users.name} &nbsp;
                          <span className="text-muted mb-0 text-small">
                            {value.fecha}
                            <i className="simple-icon-clock mr-1" />
                            {value.hora}
                          </span>{" "}
                        </p>
                        <p className="mt-3">
                          <i className="simple-icon-bubble mr-1 heading-icon" />
                          {value.nota}
                        </p>
                      </div>
                    </div>
                  ))}

                  <form onSubmit={handleSubmit(onSubmitNota)}>
                    <div className="comment-contaiener">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agregar Nota"
                          ref={notaRef}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="submit">
                            <span className="d-inline-block">Guardar</span>
                            <i className="simple-icon-arrow-right ml-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade bd-example-modal-sm"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                ¿Realmente desea eliminar el registro?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <button className="btn btn-danger" type="button" data-dismiss="modal" aria-label="Close">
                <span className="d-inline-block">ELIMINAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DetalleProducto;
