import React, { useState, useEffect, createRef } from "react";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import axiosClient from "../../axios-client";
import { useParams } from "react-router-dom";

function CargarPlanos(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('planos_add') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { id } = useParams();

  const [codigo, setCodigo] = useState([]);
  const [titulo, setTitulo] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const dataProducto = () =>{
    axiosClient.post('/obtener-producto', {id: id})
    .then(({ data }) => {
      setCodigo(data[0].codigo);
      setTitulo(data[0].titulo);
    }).catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = (payload) => {
    axiosClient
      .post(
        `/cargar-plano`,
        {
          file: selectedFile,
          id_producto: id,
          codigo
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        window.location = "/planos-todos";
      });
  };

  useEffect(() => {
    dataProducto();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Nuevo Plano</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-xl-7 ">
            <div className="card mb-4 justify-content-center">
              <div className="lightbox">
                <img
                  alt="detail"
                  src="../img/fotoPlano.jpeg"
                  className="responsive border-0 card-img-top mb-3"
                />
              </div>
              <div className="card-body">
                <h5 className="mb-4">Datos</h5>
                <form
                  id="exampleFormTopLabels"
                  className="tooltip-right-bottom"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group has-float-label">
                    <label>Código</label>
                    <input
                      type="text"
                      className="form-control"
                      value={codigo}
                      disabled
                    />
                  </div>
                  <div className="form-group has-float-label">
                    <span>Producto</span>
                    <input
                      type="text"
                      className="form-control"
                      value={titulo}
                      disabled
                    />
                  </div>
                  {/*<div className="card-body">
                    <h5 className="mb-4">Agregar Plano</h5>
                    <div className="dropzone"></div>
                  </div>*/}

                  <div className="form-group mb-5">
                    <label>Cargar Plano</label>
                    <div>
                      <label
                        className="btn btn-outline-primary btn-upload"
                        htmlFor="inputImage"
                        title="Cargue archivo de imagen"
                      >
                        <input
                          id="inputImage"
                          type="file"
                          className="sr-only"
                          accept=".pdf"
                          onChange={(e) => setSelectedFile(e.target.files[0])}
                          required
                        />
                        Seleccione archivo
                      </label>

                      &nbsp;<label>{selectedFile ? selectedFile.name : ''}</label>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-secondary btn-block mb-1"
                  >
                    SUBIR PLANO
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CargarPlanos;
