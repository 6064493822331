import React, { createRef, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { useForm } from "react-hook-form";

function NuevoDespacho(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('despachos_add') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [pedidos, setPedidos] = useState([]);
  const [notas, setNotas] = useState([]);

  const notaRef = createRef();

  const consultarPedidos = () => {
    let payload = {
      estado: '1'
    }
    axiosClient.post('/consultar-pedidos', payload)
    .then(({data})=> {
      setPedidos(data);
    })
  }

  const verNotas = (id_registro) => {
    let payload = {
      id_registro
    }
    localStorage.setItem('id_pedido', id_registro);
    axiosClient.post('/notas-pedido', payload)
    .then(({data})=> {
      setNotas(data)
    })
  }

  const onSubmitNota = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_pedido: localStorage.getItem("id_pedido"),
      nota: notaRef.current.value
    }
    axiosClient.post("/agregar-nota-pedido", payload)
      .then(({ data_ }) => {
        verNotas(localStorage.getItem("id_pedido"))
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  const irAgregar = () => {
    window.location = "/pedidos-nuevo";
  };

  const verDetallePedido = (id_pedido) => {
    window.location = '/detalle-pedido/'+id_pedido
  }

  useEffect(() => {
    consultarPedidos();
  }, []);

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Despachos</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <table className="data-table cont_tabla">
                  <thead>
                    <tr>
                      <th>Pedido</th>
                      <th>Fecha</th>
                      <th>Vendedor</th>
                      <th>Cliente</th>
                      <th>Despachar</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pedidos.map((registro, i) => (
                    <tr key={i}>
                      <td onClick={() => verDetallePedido(registro.id)} className="activatePointer">{registro.id}</td>
                      <td>{registro.fecha}</td>
                      <td><strong>{registro.nombreVendedor}</strong></td>
                      <td>{registro.razon_social ? registro.razon_social+' - ' : ''}{registro.nombreCliente}</td>
                      <td><a href={'/despachos-hacer/'+registro.id}><button type="button" className="btn btn-primary mb-1">Hacer Despacho</button></a></td>
                      <td>
                        <button type="button" className={registro.clase} data-toggle="modal" data-target="#exampleModal" onClick={() => verNotas(registro.id)}>
                          {registro.estado}
                        </button>
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Notas:
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <h5 className="card-title" />
                <div>
                {notas.map((value, key) => (
                  <div key={key} className="d-flex flex-row mb-3 border-bottom justify-content-between">
                    <a>
                        <img
                           src={value.users.image}
                          alt={value.users.name}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                      </a>
                      <div className="pl-3 flex-grow-1">
                        <p className="list-item-heading mb-0 ">
                          {value.users.name} &nbsp;
                          <span className="text-muted mb-0 text-small">
                            {value.fecha}
                            <i className="simple-icon-clock mr-1" />
                            {value.hora}
                          </span>{" "}
                        </p>
                        <p className="mt-3">
                          <i className="simple-icon-bubble mr-1 heading-icon" />
                          {value.nota}
                        </p>
                      </div>
                  </div>
                  ))}
                  <form onSubmit={handleSubmit(onSubmitNota)}>
                    <div className="comment-contaiener">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agregar Nota"
                          ref={notaRef}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="submit">
                            <span className="d-inline-block">Guardar</span>
                            <i className="simple-icon-arrow-right ml-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

    </main>
  );
}

export default NuevoDespacho;
