import React from 'react'

function CopiaSeguridad(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('configuracion_view') && permisos!='-1'){
    window.location = '/home';
  }
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Copia de Seguridad</h1>
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 mb-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Generar archivo de Backup</h5>
              </div>
              <div className="card-footer">
                <a href="https://eva.induman.co/api/backup.php" target="_blank" className="btn btn-primary btn-xs mb-1">
                  Descargar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default CopiaSeguridad